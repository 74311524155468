import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import CardList from '../components/CardList';
import Footer from '../components/Footer';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import './HomePage.css'; 
import data from '../data.json';
import CancelIcon from '@mui/icons-material/Cancel'; // Import the cancel icon
import Chip from '@mui/material/Chip';
import Cookies from '../components/Cookies';
import Courses from './Courses';
import AITimeline from './Timeline';
import CountdownToAGI from './Countdown';
import Glossary from './Glossary';
import ScrollToTopButton from '../components/ScrollToTopButton'; 
import Pill from '../components/Pill';

const Historical = () => {
  const [showJumbotron, setShowJumbotron] = useState(true); // State to control Jumbotron visibility

  
  const [selectedTag, setSelectedTag] = useState("All");
  
  const handleTagChange = (tag) => {
    // Function to update the selected tag
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    setSelectedTag(tag);

    
  };






  const clearTag = () => {
    setSelectedTag("All"); // Reset to "All"
  };

  useEffect(() => {
    console.log("HomePage re-rendered"); // Ensure re-render is happening
  }, [selectedTag]); // Trigger re-render when selectedTag changes

  // Filter tools based on the selected tag
  const filteredTools = selectedTag === "All"
    ? data.tools
    : data.tools.filter(tool => tool.tags && tool.tags.includes(selectedTag));
    


    const onClearTag = () => {
      setSelectedTag("All"); // Reset to all tools
    };



    const resetToDefault = () => {
      setSelectedTag("All"); // Reset the tag to "All"
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top smoothly
    };


  




    return (
        <div
      style={{
        minHeight: '100vh', // Ensures full viewport height
        backgroundColor: '#f7f1e3', // Specific color (in this case, a shade of blue)
        paddingBottom: '0px', // Padding to ensure footer doesn't overlap content
        
      }}
    >
      
      <Container maxWidth={false} style={{ maxWidth: '18em', margin: '0 auto', padding: '20px', top: '20em', position: 'relative' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2 style={{ fontSize: '25px', marginRight: '10px', marginBottom: '0', marginBottom: '25px' }}>Discover Your Next Favorite <span className="highlight8">Podcast</span></h2>
        <img src="/yoyo.png" alt="Description of the image" style={{ maxWidth: '100%', height: 'auto', marginLeft: '10px' }} />
      </div>
    </Container>
          
        </div>
        
      );
    };
    
    export default Historical;
