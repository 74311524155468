import React, { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import data from '../data.json'; // Assuming data.json is in the parent directory
import useMediaQuery from '@mui/material/useMediaQuery';


const Pill = () => {
    const [randomIndex, setRandomIndex] = useState(null);
    const isMobile = useMediaQuery('(max-width: 600px)');


    useEffect(() => {
        // Retrieve stored index and timestamp from localStorage
        const storedIndex = localStorage.getItem('podcastIndex');
        const storedTimestamp = localStorage.getItem('podcastTimestamp');

        if (storedIndex !== null && storedTimestamp !== null) {
            const storedDate = new Date(parseInt(storedTimestamp));
            const currentDate = new Date();

            // Check if more than 7 days have passed since last update
            if (currentDate - storedDate > 1 * 60 * 60 * 1000) { // 1 hour in milliseconds
                // Select a new random index if more than a week has passed
                const newIndex = Math.floor(Math.random() * data.tools.length);
                setRandomIndex(newIndex);
                localStorage.setItem('podcastIndex', newIndex);
                localStorage.setItem('podcastTimestamp', currentDate.getTime().toString());
            } else {
                // Use the stored index if within the week
                setRandomIndex(parseInt(storedIndex));
            }
        } else {
            // First time setup: select a random index and store it
            const newIndex = Math.floor(Math.random() * data.tools.length);
            setRandomIndex(newIndex);
            const currentDate = new Date();
            localStorage.setItem('podcastIndex', newIndex);
            localStorage.setItem('podcastTimestamp', currentDate.getTime().toString());
        }
    }, []);

    if (randomIndex === null || !data.tools[randomIndex]) {
        return null; // Return null while waiting for index to be determined
    }

    const randomPodcast = data.tools[randomIndex];
    const podcastId = randomPodcast.id.replace('tool', '');

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: isMobile ? '1.3em' : '3em', marginTop: isMobile ? '5px' : '20px'   }}>
            <Chip className="glow-on-hover" label={
                <span style={{ display: 'flex', color: '#333', justifyContent: 'center', margin: '0 auto', position: 'relative', top: '5px'}}>
                    Podcast of the Hour:    
                                                        
                    <Link to={`/podcast/${podcastId}`} style={{ textDecoration: 'none', marginLeft: '5px', color: '#E74C3C' }}>
                        {randomPodcast.name}
                    </Link>
                    <div style={{ position: 'relative', marginLeft: '10px', bottom: '3px' }}>
                        <Link to={`/podcast/${podcastId}`}>
                            <img src={`/${randomPodcast.icon}`} style={{ width: '20px', borderRadius: '4px' }} alt="Podcast Icon" />
                        </Link>
                    </div>
                </span>
            } />
        </Box>
    );
};

export default Pill;
