import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Button } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CloseIcon from '@mui/icons-material/Close';

const Landing = () => {
  const [selectedTag, setSelectedTag] = useState("All");
  
  const handleTagChange = (tag) => {
    // Function to update the selected tag
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    setSelectedTag(tag);
  };

  useEffect(() => {
    console.log("HomePage re-rendered"); // Ensure re-render is happening
  }, [selectedTag]); // Trigger re-render when selectedTag changes

  const resetToDefault = () => {
    setSelectedTag("All"); // Reset the tag to "All"
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top smoothly
  };

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#f7f1e3', paddingBottom: '0px', fontSize: '16px' }}>
      {/* Navbar component */}
      <Navbar onTagChange={handleTagChange} onLogoClick={resetToDefault} />
      
      {/* Constrained content area */}
      <Container maxWidth={false} style={{ padding: '20px', margin: '0 auto', marginTop: '7em', marginLeft: '0em' }}>
        <Grid container spacing={3} justifyContent="center" >
          <Grid item xs={12} md={3} style={{ textAlign: 'left', marginRight: '0em', display: 'flex', justifyContent: 'center', paddingRight: '0px' }}>
            <div className="jumbotron2" style={{ padding: '10px', margin: '0 auto', position: 'relative' }}>
              <Typography variant="h1" style={{ color: '#333', fontSize: '50px', fontWeight: '600' }}>Submit Your Podcast,  <span className="highlight8" style={{color: '#333'}}>Expand Your Reach</span></Typography>
              <Typography variant="body1" style={{ marginTop: '2em', marginBottom: '2em', color: '#333', textAlign: 'left' }}>Ready to boost your podcast's exposure? Submit your podcast on our platform to grow your audience. We'll help you get it in front of the right people, making sure your voice is heard.</Typography>
              <Button variant="contained" id="sales-button" style={{ backgroundColor: '#fcaa97', color: '#333', width: '10em'}}>Get Listed</Button>
            </div>
          </Grid>



          
          <Grid item xs={12} md={3} style={{ textAlign: 'left', position: 'relative',  left: '0em', marginLeft: '0em' }}>
          <div className="benefits" style={{ color: '#333', fontSize: '14px', margin: '0 auto', position: 'relative', marginTop: '3em', padding: '10px', marginLeft: '4em', '@media (max-width:600px)': {marginLeft: '0em'} }}>
              <Typography variant="h2" style={{ fontSize: '18px', marginBottom: '20px' }}>You benefit from:</Typography>
              <ul style={{ listStyleType: 'none', paddingLeft: '0', textAlign: 'left' }}>
                <li style={{ color: '#333', marginBottom: '10px' }}><span style={{ color: '#fcaa97', marginRight: '5px' }}>✔</span> Being listed across our site</li>
                <li style={{ color: '#333', marginBottom: '10px' }}><span style={{ color: '#fcaa97', marginRight: '5px' }}>✔</span> Being categorized in the NEW PODCASTS category</li>
                <li style={{ color: '#333', marginBottom: '10px' }}><span style={{ color: '#fcaa97', marginRight: '5px' }}>✔</span> Being categorized where your podcast belongs (ie. true crime)</li>
                <li style={{ color: '#333', marginBottom: '10px' }}><span style={{ color: '#fcaa97', marginRight: '5px' }}>✔</span> Reaching a wider audience</li>
                <li style={{ color: '#333', marginBottom: '10px' }}><span style={{ color: '#fcaa97', marginRight: '5px' }}>✔</span> Gaining more credibility</li>
                <li style={{ color: '#333', marginBottom: '10px' }}><span style={{ color: '#fcaa97', marginRight: '5px' }}>✔</span> An opportunity to make our Featured section</li>

                <li style={{ color: '#333', marginBottom: '10px' }}><span style={{ color: '#fcaa97', marginRight: '5px' }}>✔</span> A continuous chance to be PODCAST OF THE DAY! No limit!</li>

              </ul>
            </div>
          </Grid>


          <Grid container spacing={3} justifyContent="center" marginBottom='26em'>
  {/* Grid items as before */}
</Grid>
{/* Centered section */}
{/*<div style={{ backgroundColor: '#444', borderRadius: '12px', padding: '20px', marginTop: '15em', textAlign: 'center' }}>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li style={{ color: '#ccc', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <CloseIcon style={{ color: '#fc0000', marginRight: '10px' }} />
          It's difficult to get new listeners
        </li>
        <li style={{ color: '#ccc', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <CloseIcon style={{ color: '#fc0000', marginRight: '10px' }} />
          Audience reach is limited
        </li>
        <li style={{ color: '#ccc', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <CloseIcon style={{ color: '#fc0000', marginRight: '10px' }} />
          Starting out is slow
        </li>
        <li style={{ color: '#ccc', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <CloseIcon style={{ color: '#fc0000', marginRight: '10px' }} />
          Millions of podcasts
        </li>
        <li style={{ color: '#ccc', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <CloseIcon style={{ color: '#fc0000', marginRight: '10px' }} />
          New podcasts aren't easily found
        </li>
      </ul>
    </div>*/}


        </Grid>
       {/* <p style={{ color: '#ddd', display: 'flex', justifyContent: 'center', marginTop: '3em', fontSize: '14px'}}><ArrowDownwardIcon style={{fontSize: '12px', marginRight: '3px', alignContent: 'center', position: 'relative', top: '3px'}}/>There's a solution...</p>*/}
      </Container >
      <Footer />
    </div>
  );
};

export default Landing;
