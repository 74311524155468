import React, { useState, useEffect } from 'react';
import { Grid, Button, Menu, MenuItem, Divider, Chip, Box, styled } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToolCard2 from '../components/ToolCard2';
import toolData from '../data.json';
import CancelIcon from '@mui/icons-material/Cancel';
import useMediaQuery from '@mui/material/useMediaQuery';
import data from '../data.json';



const CardList = ({ selectedTag = 'All', selectedTag2, onClearTag, podcastOfTheDay }) => {


  
  
  const initialDisplayCount = 8; // Starting number of cards to display
  const incrementCount = 4 // Number of cards to add when showing more

  const [sortType, setSortType] = useState('Random'); // Default to 'newest'
  const [tools, setTools] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  const [randomizedTools, setRandomizedTools] = useState([]);
 

  const podcastKeys = Object.keys(data);

  const [initialTools, setInitialTools] = useState([]); // Add state to store the initial tools






// Function to fetch and set the initial tools
const fetchInitialTools = () => {
  const initialToolsData = toolData.tools;

  setInitialTools(initialToolsData); // Set the initial tools
};

// useEffect to fetch the initial tools only once
useEffect(() => {
  fetchInitialTools();
}, []);







// Inside your sortTools function

const sortTools = (type, data) => {
  let sortedTools = [...data]; // Make a fresh copy of the data

  switch (type) {
    case 'Newest':
      sortedTools.sort((a, b) => parseInt(b.id.replace('tool', '')) - parseInt(a.id.replace('tool', ''))); // Newest to oldest
      break;
    case 'A-Z':
      sortedTools.sort((a, b) => a.name.localeCompare(b.name)); // Alphabetical
      break;
    case 'Z-A':
      sortedTools.sort((a, b) => b.name.localeCompare(a.name)); // Reverse alphabetical
      break;
    case 'Random':
      // Check if tools are already randomized
      if (randomizedTools.length === 0) {
        // If not randomized, perform randomization
        sortedTools.sort(() => Math.random() - 0.5); // Shuffle
        setRandomizedTools(sortedTools); // Save the randomized list
      } else {
        // If already randomized, use the existing randomized list
        sortedTools = randomizedTools;
      }
      break;
    default:
      // In case of unknown sortType, return the original array
      sortedTools = [...data];
      break;
  }

  return sortedTools;
};







  const handleSortChange = (type) => {
    // Close the menu
    handleMenuClose();
   
  
  
    // Update the sort type
    setSortType(type);
  
    // Reset the display count when the sort changes
    setDisplayCount(initialDisplayCount);
  };



  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };




  const [hasMoreTools, setHasMoreTools] = useState(true);

  // Function to check if there are more tools available to display
  const checkMoreTools = (newCount, totalTools) => {
    return newCount < totalTools;
  };


 



  
  
  const handleShowMore = () => {
    const newCount = displayCount + incrementCount;
    setDisplayCount(newCount); // Update the displayed count

    const initialTools = toolData.tools;

    let filteredTools;
    if (selectedTag === 'All') {
        filteredTools = initialTools;
    } else {
        // Filter based on selected tag
        filteredTools = initialTools.filter((tool) => tool.tags?.includes(selectedTag));
    }

    let sortedFilteredTools;
    // Apply additional filtering based on selectedTag2
    switch (selectedTag2) {
        case 'Featured':
            sortedFilteredTools = initialTools.filter((tool) => tool.isFeatured);
            break;
        case 'Top 10':
            sortedFilteredTools = initialTools.filter((tool) => tool.isTopTen);
            break;
        case 'Most Popular':
            sortedFilteredTools = initialTools.filter((tool) => tool.isPopular);
            break;
        case 'Wondery':
            sortedFilteredTools = initialTools.filter((tool) => tool.isWondery);
            break;
        case 'Parcast':
              sortedFilteredTools = initialTools.filter((tool) => tool.isParcast);
              break;
        case 'Rogan':
              sortedFilteredTools = initialTools.filter((tool) => tool.isRogan);
              break;
        default:
            // If no specific tag is selected, keep the filteredTools as is
            sortedFilteredTools = filteredTools;
            break;
    }

    

    

     // Sort the filtered and sorted tools
     const sortedTools = sortTools(sortType, sortedFilteredTools);

     setTools(sortedTools.slice(0, newCount)); // Display only the new count of tools



    // Check if there are more tools available
    const moreToolsAvailable = checkMoreTools(newCount, sortedTools.length);
    setHasMoreTools(moreToolsAvailable);
};


  const isMobile = useMediaQuery('(max-width: 600px)');

  
  


  
  const totalToolsCount = toolData.tools
  .filter((tool) => selectedTag === 'All' || tool.tags?.includes(selectedTag))
  .length;



  useEffect(() => {
    const initialTools = data.tools;
  
    let filteredTools;
    if (selectedTag === 'All') {
      filteredTools = initialTools;
    } else {
      filteredTools = initialTools.filter((tool) => tool.tags?.includes(selectedTag));
    }
  
    let sortedFilteredTools;
    switch (selectedTag2) {
      case 'Featured':
        sortedFilteredTools = initialTools.filter((tool) => tool.isFeatured);
        break;
      case 'Top 10':
        sortedFilteredTools = initialTools.filter((tool) => tool.isTopTen);
        break;
      case 'Most Popular':
        sortedFilteredTools = initialTools.filter((tool) => tool.isPopular);
        break;
      case 'Wondery':
          sortedFilteredTools = initialTools.filter((tool) => tool.isWondery);
          break;  
      case 'Parcast':
          sortedFilteredTools = initialTools.filter((tool) => tool.isParcast);
          break; 
      case 'Rogan':
          sortedFilteredTools = initialTools.filter((tool) => tool.isRogan);
          break; 
      default:
        sortedFilteredTools = filteredTools;
        break;
    }
  
    let sortedTools = sortTools(sortType, sortedFilteredTools);
  
   
   
   
   
    if (podcastOfTheDay) {
      const index = sortedTools.findIndex(tool => tool.id === podcastOfTheDay.id);
      if (index !== -1) {
        sortedTools.splice(index, 1);
      }
      sortedTools.unshift(podcastOfTheDay);
    }
  
    setTools(sortedTools.slice(0, initialDisplayCount));
    const moreToolsAvailable = checkMoreTools(initialDisplayCount, sortedTools.length);
    setHasMoreTools(moreToolsAvailable);
  }, [selectedTag, selectedTag2, sortType, podcastOfTheDay]);






  return (
    <div style={{marginTop: '5em', marginTop: isMobile ? '3em' : '5em' }}>
       <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        alignItems: isMobile ? 'center' : 'flex-start', // Center on mobile, align left on larger screens
        paddingLeft: isMobile ? '0px' : '20px',
     
      }}
    >
      <div id="both-filters">
      {/* Filter/Sort Dropdown Button */}
      <Button id="the-filter"
        variant="outlined"
        
        endIcon={<ArrowDropDownIcon />}
        onClick={handleMenuOpen}
        
        style={{ fontSize: '14px', textTransform: 'none', marginBottom: '10px', backgroundColor: '#ffe6c7', color: '#333', border: 'none', marginLeft: '10px', boxShadow: '0px 2px 5px #666'
       
      }}
      >
               {sortType} {/* Display the current sort in all uppercase */}

      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={{width: '10em'}}
      >
        <MenuItem onClick={() => handleSortChange('Newest')} style={{fontSize: '14px'}}>Newest</MenuItem>
        <MenuItem onClick={() => handleSortChange('Random')} style={{fontSize: '14px'}}>Random</MenuItem>
        <MenuItem onClick={() => handleSortChange('A-Z')} style={{fontSize: '14px'}}>A-Z</MenuItem>
        <MenuItem onClick={() => handleSortChange('Z-A')} style={{fontSize: '14px'}}>Z-A</MenuItem>
        {/*
        <Divider />
        <MenuItem onClick={() => handleSortChange('Our Top 10')}>Our Top 10</MenuItem>
        <MenuItem onClick={() => handleSortChange('Featured')}>Featured</MenuItem>
    <MenuItem onClick={() => handleSortChange('Big Tech AI')}>Big Tech AI</MenuItem>*/}
      </Menu>

       {/* Show the selected tag with a clear option, only if it's not "All" */}
       {selectedTag !== "All" && (
          <Chip 
            label={selectedTag}
            onDelete={onClearTag} // Clear the selected tag
            deleteIcon={<CancelIcon />} // Use the cancel icon
            sx={{
              backgroundColor: '#2C3E50', // Custom background color
              color: 'white', // Text color
              position: 'relative',
              
              bottom: '5px',
              marginLeft: '10px',
              boxShadow: '0px 2px 5px #666',
              borderRadius: '5px', // Custom border radius
              padding: '0 8px', // Custom padding
              fontSize: '14px', // Custom font size
              fontWeight: '500', // Custom font weight
              '& .MuiChip-deleteIcon': {
                color: '#333', // Custom color for the delete icon
                '&:hover': {
                  color: '#ff0000', // Change color on hover
                },
              },
            }}
          />


          
        )}

{selectedTag === "All" && (
  <Chip
    label="All Podcasts"

   
    sx={{
      backgroundColor: '#2C3E50',
      color: 'white',
      position: 'relative',
      boxShadow: '0px 2px 5px #666',
     
      bottom: '5px',
      marginLeft: '10px',
      borderRadius: '5px',
      padding: '17px 8px',
      fontSize: '14px',
      fontWeight: '500',
      '& .MuiChip-deleteIcon': {
        color: '#333',
        '&:hover': {
          color: '#ff0000',
        },
      },
    }}
  />
)}
      </div>
    
      {/* ToolCard Grid */}
      <Grid container spacing={2}>
        {tools.map((tool) => (
          <Grid item xs={12} sm={6} md={3} key={tool.id}>
              
        
            <ToolCard2 tool={tool} isPodcastOfTheDay={tool.id === podcastOfTheDay?.id } />
           
            
            
        
          </Grid>
        ))}
      </Grid>
        
     
      </Box>
       {/* "Show More" Button */}
       {hasMoreTools && (
        <div style={{ textAlign: 'center', marginTop: '40px', justifyContent: 'center', display: 'flex' }}>
          <Button
            variant="contained"
            
            onClick={handleShowMore}
            style={{ textTransform: 'none', display: 'flex', marginBottom: '20em', backgroundColor: '#2C3E50', color: 'white', position: 'relative', justifyContent: 'center', fontSize: '12px' }}
          >
            Show More
          </Button>
        </div>
      )}
    </div>
  );
};

export default CardList;
