import React, { useRef, useEffect, useState } from 'react';

import { Card, CardContent, CardActions, Button, Typography, Chip, Avatar, Tooltip } from '@mui/material';
import './TagStyles.css'; // Import the CSS file with your tag styles
import './ToolCard.css'; 
import SendIcon from '@mui/icons-material/Send';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import toolData from '../data.json';
import PodcastPage from '../pages/PodcastPage';
import LazyLoad from 'react-lazyload';

const ToolCard = ({ tool, isPodcastPage, isPodcastOfTheDay, podcastOfTheDay, onInfoClick }) => {
  const id = tool.id.replace('tool', '');
  const descriptionRef = useRef(null);
  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const [isHovered, setIsHovered] = useState(false);


  // Function to determine which medal to display
  const getMedalIcon = (tool) => {
    if (tool.isGold) {
      return '/gold-medal.png';
    } else if (tool.isSilver) {
      return '/silver-medal.png';
    } else if (tool.isBronze) {
      return '/bronze-medal.png';
    } else {
      return null;
    }
  };

  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleInfoClick = () => {
   
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  };


  // Default icon path, ensure it's correct for your project structure
  const iconPath = tool.icon ? `/${tool.icon}` : '/icons/default-icon.png'; 

  const tooltipText = tool.price === 'Freemium' ? 'Free access with paid upgrades' : null;

  const medalIcon = getMedalIcon(tool);


  useEffect(() => {
    if (descriptionRef.current) {
      setDescriptionHeight(descriptionRef.current.clientHeight);
    }
  }, [tool.description]);


  const formatDescription = () => {
    return isPodcastPage ? tool.description.split('/') : [tool.description];
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
<div style={{ position: 'relative', marginTop: '0px', paddingTop: '0px',}}>
      {isPodcastOfTheDay && (
        <div
        className={`product-of-the-month ${isMobile ? 'mobile' : 'desktop'}`} style={{height: '0em'}}
      >Podcast of the Day</div>
      )}
    <Card 
    
      sx={{ 
        width: 285,
        height: 350,
        display: 'flex',
        padding: '0px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: '0px 3px 5px #888',
        borderRadius: '12px',
        marginRight: 'auto',
        marginLeft: 'auto',
        overflow: 'hidden',
        border: isPodcastOfTheDay ? '3px solid #2C3E50' : 'none',
        ...(isPodcastPage && { 
          padding: '0px 0px' , // Increase padding for more space
          boxShadow: '0px 2px 8px #333', // Add a shadow for depth
          
          height: 370 + descriptionHeight,
          width: 420,
        width: isMobile ? '360px' : '420px',
        }) 
        


      }}
      
    >
      
      


<div style={{ 
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#ede7d9',
        padding: '10px',
        paddingTop: '7px',
        paddingBottom: '5px',
        
        
      }}>
        <div style={{ 
          width: '30%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#eee',
          padding: '0px',
          borderRadius: '8px',
          
          
        }}>
          
          <img
            src={iconPath}
            alt={`${tool.name} icon`}
            style={{ 
              width: '130px', 
              height: '130px', 
              objectFit: 'contain',
             boxShadow: '0 1px 4px #333',
              borderRadius: '12px',
              position: 'relative',
              
              left: '1.4em',
              ...(isPodcastPage && { 
                padding: '0px', // Increase padding for more space
                boxShadow: '0px 1px 2px #666', // Add a shadow for depth
                height: 200,
                width: 200,
                position: 'relative',
                left: '2.3em'
              }) 
            }}
          />
       
        </div>


<div style={{display: 'grid'}}>  
  
  <Link to={`/podcast/${id}`} onClick={handleInfoClick}  element={<PodcastPage toolData={toolData} />}><img src="/info.png" alt="Info icon" 
                style={{ width: '14px', height: '14px', position: 'relative', bottom: '3.6em',  left: '10.9em', ...(isPodcastPage && { 
                padding: '0px', // Increase padding for more space
                display: 'none',
               
                position: 'relative',
                left: '2.1em'
              }) }} /></Link></div>

        <div style={{ width: '40%', paddingLeft: '10px', textAlign: 'center', position: 'relative', left: '3em'}}>
       

          <Typography variant="h6" style={{  fontSize: '16px', fontWeight: '600', color: '#2C3E50', overflow: 'hidden', maxHeight: '8em', textOverflow: 'ellipsis', ...(isPodcastPage && { 
                padding: '0px', // Increase padding for more space
              
                fontSize: '22px',
                position: 'relative',
                left: '1.5em'
              })  }}>
            {tool.name}
            {medalIcon && (
              <img
                src={medalIcon}
                alt="Medal"
                style={{ width: '20px', height: '20px', marginLeft: '5px', position: 'relative', top: '5px',  }}
                
              />
            )}

          </Typography>
        </div>


 </div>       
      

{/*
      <Link to={`/podcast/${id}`}>View Podcast</Link>
          */}


     <CardContent sx={{ flexGrow: 1, backgroundColor: '#ede7d9', position: 'relative' , paddingTop: '3px'}}>
        
        <div style={{ position: 'absolute', top: 0, left: 0,  width: '100%', height: '0em', backgroundColor: '#555', zIndex: 0 }} />
        
<div  ref={descriptionRef}>
        
{/*<div style={{marginBottom: '4em', height: '0em', marginTop: '1.7em'}}>
  <a href="#" style={{ textDecoration: 'none',  display: 'flex', justifyContent: 'center', }}>
    <img
      src={iconPath}
      alt={`${tool.name} icon`}
      style={{ width: '100px', height: '90px', border: '3px solid #555', marginTop: '0em', zIndex: '999',  backgroundColor: 'white', borderRadius: '12px', position: 'relative', bottom: '1.7em' }}
    />
  </a> 
        </div>  */}


          



          <Typography variant="body1" style={{ fontSize: '13px', color: '#666', textAlign: 'center', marginTop: '8px', marginBottom: '0px',   overflow: 'hidden', height: '7.2em', 
    textOverflow: 'ellipsis', 
    display: '-webkit-box', 
    WebkitLineClamp: 5, 
    WebkitBoxOrient: 'vertical', ...(isPodcastPage && { 
      
      marginTop: '1em',
      fontSize: '15px',
      position: 'relative',
     
      height: '', 
    display: '-webkit-box', 
    WebkitLineClamp: 200, 
    
    }) }}>
            {tool.description}
            
          </Typography>


          
     
        </div>
      
    
      <div style={{ flexGrow: 1, paddingBottom: '0px', display: 'flex', justifyContent: 'center', position: 'relative', top: '0px', marginBottom: '0px',   ...(isPodcastPage && { 
                padding: '0px', // Increase padding for more space
                top: '1em',
                fontSize: '14px',
                position: 'relative',
                
              })}}>
        <div style={{ height: '0px', overflow: 'hidden', padding: '1px', color: '#E74C3C', display: 'flex', flexWrap: 'wrap', marginTop: '.6em', height: '3.5em', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          {tool.tags && tool.tags.map((tag, index) => (
            <Chip 
              key={index}
              label={tag}
              size="small"
              variant="outlined"
              
              style={{ backgroundColor: '#ffe6c7', color: '#E74C3C', marginRight: '4px', marginBottom: '4px', border: 'none', color: '', fontSize: '11px' , ...(isPodcastPage && { 
                padding: '0px', // Increase padding for more space
                bottom: '0em',
                fontSize: '14px',
                position: 'relative',
                
              })
              }}
            />
          ))}
          
        </div>

        
      </div>
{/*
<div style={{display: 'grid', justifyContent: 'center'}}>
      {tooltipText ? (
            <Tooltip title={tooltipText} arrow>
              <Typography variant="subtitle1" style={{  backgroundColor: '#333', color: 'white', borderRadius: '20px', fontSize: '12px', padding: '1px 10px',       display: 'inline-block', justifyContent: 'center',
 }}>  
                {tool.price}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="subtitle1" style={{  backgroundColor: '#333', color: 'white', borderRadius: '15px', fontSize: '12px', padding: '1px 10px'  ,       display: 'inline-block', justifyContent: 'center',
          }}>
              {tool.price}
            </Typography>
          )}
       </div>

        */}
<div style={{display: 'flex', justifyContent: 'center', marginTop: '8px', ...(isPodcastPage && { 
                padding: '0px', // Increase padding for more space
                top: '1em',
                
                position: 'relative',
                
              })}}>
<div style={{ fontSize: '14px', color: '#333', ...(isPodcastPage && { 
                padding: '0px', // Increase padding for more space
                top: '0em',
                fontSize: '16px',
                position: 'relative',
                
              }) }}>
          
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', bottom: '7px', left: '0px' }}>
          <a href={tool.websiteUrl} target="_blank" rel="noopener noreferrer">
            <img src="/listenspotify.png" alt="Spotify" style={{ width: '7.5em', height: '1.9em', marginRight: '8px' }} />
          </a>
          <a href={tool.appleUrl} target="_blank" rel="noopener noreferrer">
            <img src="/listenapple.png" alt="Apple Podcasts" style={{ width: '7.5em', height: '1.9em', position: 'relative', top: '0px' }} />
          </a>
        </div>
</div>

{/*
      <CardActions sx={{ justifyContent: 'center', paddingBottom: '0px', display: 'flex', marginTop: '0px', bottom: '5px', position: 'absolute' , height: '2em', left: '4.5em'}}>
      <a href={tool.websiteUrl} id="visit-website-button" variant="contained" rel="noopener noreferrer" target="_blank" style={{textDecoration: 'none' }} >

      Explore Episodes
        </a>
      </CardActions>
      */}  



      </CardContent>
    </Card>
    </div>
  );
};

export default ToolCard;
