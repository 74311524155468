import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import CardList from '../components/CardList';
import Footer from '../components/Footer';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import './HomePage.css'; 
import data from '../data.json';
import CancelIcon from '@mui/icons-material/Cancel'; // Import the cancel icon
import Chip from '@mui/material/Chip';
import Cookies from '../components/Cookies';
import Courses from './Courses';
import AITimeline from './Timeline';
import CountdownToAGI from './Countdown';
import Glossary from './Glossary';
import ScrollToTopButton from '../components/ScrollToTopButton'; 
import Pill from '../components/Pill';

import toolData from '../data.json';
import Box from '@mui/material/Box';
import Topbar from '../components/Topbar';
import SearchBar from '../components/SearchBar';
import PodcastPage from './PodcastPage';
import NewsletterCTA from '../components/NewsletterCTA';


const HomePage = () => {
  const [showJumbotron, setShowJumbotron] = useState(true); // State to control Jumbotron visibility
  const [searchResults, setSearchResults] = useState(data.tools); // Initialize with all tools

  
  const [selectedTag, setSelectedTag] = useState("All");
  const [selectedTag2, setSelectedTag2] = useState("All");
  const [selectedTag3, setSelectedTag3] = useState("All");
  const [podcastOfTheDay, setPodcastOfTheDay] = useState(null); // State for "Podcast of the Day"

  
  const handleTagChange = (tag) => {
    // Function to update the selected tag
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    setSelectedTag(tag);
    setIsJumbotronVisible(false);
    
  };

  // Function to update search results
  const updateSearchResults = (results) => {
    setSearchResults(results);
  };




  const clearTag = () => {
    setSelectedTag("All"); // Reset to "All"
  };

  useEffect(() => {
    console.log("HomePage re-rendered"); // Ensure re-render is happening
  }, [selectedTag]); // Trigger re-render when selectedTag changes



  useEffect(() => {
    const fetchPodcastOfTheDay = () => {
      const storedPodcast = JSON.parse(localStorage.getItem('podcastOfTheDay'));
      const storedTimestamp = localStorage.getItem('podcastTimestamp');
      const now = new Date().getTime();
      const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const updateTime = getUpdateTime(); // Get the next update time

      if (storedPodcast && storedTimestamp) {
        const timestamp = parseInt(storedTimestamp);
        if (now - timestamp < oneDay) {
          // Use the stored podcast if it's less than a day old
          setPodcastOfTheDay(storedPodcast);
        } else {
          // Check if it's time to update
          if (now >= updateTime) {
            setDailyPodcast();
          } else {
            // Schedule the update for the calculated time
            scheduleUpdate(updateTime - now);
          }
        }
      } else {
        // If nothing stored, set a new podcast for the day
        setDailyPodcast();
      }
    };

    const setDailyPodcast = () => {
      const podcast = getRandomPodcast();
      const timestamp = new Date().getTime();
      localStorage.setItem('podcastOfTheDay', JSON.stringify(podcast));
      localStorage.setItem('podcastTimestamp', timestamp);
      setPodcastOfTheDay(podcast);
      scheduleUpdate(getUpdateTime());
    };

    const getRandomPodcast = () => {
      const tools = data.tools; // Assuming data.json structure
      const randomIndex = Math.floor(Math.random() * tools.length);
      return tools[randomIndex];
    };

    const getUpdateTime = () => {
      // Calculate 7am EST in milliseconds since epoch
      const now = new Date();
      const utcOffset = now.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
      const estOffset = -5 * 3600000; // EST is UTC - 5 hours
      const estTime = now.getTime() + utcOffset + estOffset;
      const updateTime = new Date(estTime);
      updateTime.setUTCHours(7, 0, 0, 0); // Set to 7am EST
      return updateTime.getTime();
    };

    const scheduleUpdate = (delay) => {
      // Schedule the next update
      setTimeout(() => {
        setDailyPodcast();
      }, delay);
    };

    fetchPodcastOfTheDay(); // Fetch or set the podcast of the day on component mount
  }, []);







  // Filter tools based on the selected tag
  const filteredTools = selectedTag === "All"
    ? data.tools
    : data.tools.filter(tool => tool.tags && tool.tags.includes(selectedTag));
    


    const onClearTag = () => {
      setSelectedTag("All"); // Reset to all tools
    };

    const [isJumbotronVisible, setIsJumbotronVisible] = useState(true);
    const handleClick = () => {
      setIsJumbotronVisible(false);
    }
    

    const resetToDefault = () => {
      setSelectedTag("All"); // Reset the tag to "All"
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top smoothly
    };




    useEffect(() => {
   
    }, [selectedTag2]); // Trigger re-render when selectedTag changes
  
    // Filter tools based on the selected tag
    const filteredTools2 = selectedTag2 === "All"
      ? data.tools
      : data.tools.filter(tool => tool.tags2 && tool.tags2.includes(selectedTag2));
      
  
  
      const onClearTag2 = () => {
        setSelectedTag2("All"); // Reset to all tools
      };
  
  
  
      const resetToDefault2 = () => {
        setSelectedTag2("All"); // Reset the tag to "All"
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top smoothly
      };




      const isMobile = window.innerWidth <= 768;





      useEffect(() => {
   
      }, [selectedTag3]); // Trigger re-render when selectedTag changes
    
      // Filter tools based on the selected tag
      const filteredTools3 = selectedTag2 === "All"
        ? data.tools
        : data.tools.filter(tool => tool.tags2 && tool.tags2.includes(selectedTag2));
        
    
    
        const onClearTag3 = () => {
          setSelectedTag2("All"); // Reset to all tools
        };
    
    
    
        const resetToDefault3 = () => {
          setSelectedTag2("All"); // Reset the tag to "All"
          window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top smoothly
        };
  

        
    



  




    return (
        <div 
      style={{
        minHeight: '100vh', // Ensures full viewport height
        backgroundColor: '#f7f1e3', // Specific color (in this case, a shade of blue)
        paddingBottom: '0px', // Padding to ensure footer doesn't overlap content
        
      }}
    >
    
          <Navbar onTagChange={handleTagChange} onLogoClick={resetToDefault3} SearchBar tools={searchResults} />
           
          
          {/* Constrained content area */}
          <Container onTagChange={handleTagChange} maxWidth={false} style={{ maxWidth: '1290px', margin: '0 auto', padding: '20px' }}>
           
          <Pill />
           
          {isJumbotronVisible && <Jumbotron className="homepage-jumbotron" />}
            {/* Add other components/content here */}
          
           
           <NewsletterCTA />
       
           
          <CardList className="card-list" selectedTag={selectedTag3} onClearTag={clearTag} podcastOfTheDay={podcastOfTheDay}    
      style={{ marginTop: isMobile ? '0px' : '0px' }} // Adjust this value as needed
/>         
        <Footer />
        
        
          </Container>
         
          
        </div>
        
      );
    };
    
    export default HomePage;
