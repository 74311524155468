import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import toolData from '../data.json';
import ToolCard2 from '../components/ToolCard2'; // Import ToolCard2 component
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Pill from '../components/Pill';
import Container from '../components/Container';
import CardList from '../components/CardList';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const PodcastPage = () => {
  const { id } = useParams();
  const toolId = `tool${id}`;
  const tool = toolData.tools.find((tool) => tool.id === toolId); // Assuming toolData is correctly imported
  const [selectedTag3, setSelectedTag3] = useState("All");
  const [episodes, setEpisodes] = useState([]);
  const [selectedTag, setSelectedTag] = useState("All");
  const isMobile = useMediaQuery('(max-width: 600px)'); // Check if screen width is mobile
  const [podcasts, setPodcasts] = useState([]);

  const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
      if (location.pathname === '/podcast') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, [location.pathname]);

    return null;
  };



  const handleTagChange = (tag) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSelectedTag(tag);
  };

  const clearTag = () => {
    setSelectedTag("All");
  };

  if (!tool) {
    return <div>Podcast not found</div>;
  }




  // Extracting tags from the tool
  const toolTags = tool.tags || []; // Assuming tags are stored in tool.tags

  // Filtering podcasts based on tags of the selected tool
  const filteredPodcasts = podcasts.filter(podcast => {
    const podcastTags = podcast.tags || [];
    return podcastTags.some(tag => toolTags.includes(tag));
  });



  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#f7f1e3', paddingBottom: '0px' }}>
      <Navbar onTagChange={handleTagChange} />
      <Container style={{ backgroundColor: '#333', padding: '0 20px', maxWidth: '1100px', margin: '0 auto' }}>
        <div style={{ marginTop: '2.5em' }}>
          <Pill />
        </div>
        <div style={{ marginTop: '5em', display: 'flex', justifyContent: 'center', padding: '0 10px' }}>
          {/* Adjusted ToolCard2 with conditional styles for mobile */}
          <ToolCard2 tool={tool} isPodcastPage={true} style={{ padding: '20px', margin: '20px 0', width: '100%',  width: isMobile ? '200' : '420'  }} />
        </div>
        <div style={{ marginTop: '6em' }}>
          <CardList podcasts={filteredPodcasts} onClearTag={clearTag} />
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default PodcastPage;
