import React, { useState, useEffect } from 'react';
import './ScrollToTopButton.css';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.scrollHeight;

      // Adjust this threshold as needed to control how close the button gets to the bottom
      const threshold = 10;

      if (scrollY > threshold && scrollY + windowHeight < bodyHeight - threshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    
    <button
      className={`scroll-to-top-button ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
      style={{ opacity: isVisible ? 1 : 0, transition: 'opacity 0.3s', backgroundColor: 'transparent' }}
    >
     <ArrowUpwardIcon style={{position: 'relative', bottom: '6px', right: '5.9px', width: '15px', height: '15px', color: 'black'}}/>
    </button>
  );
}

export default ScrollToTopButton;
