import { createTheme, ThemeProvider } from '@mui/material/styles';

// Define your custom theme with the desired font
const theme = createTheme({
  typography: {
    fontFamily: 'Bricolage Grotesque, sans-serif', // Default font for all typography
  
  },
  button: {
    color: '#ffdaa6',
  },

  
});

export default theme;
