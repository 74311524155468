import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Box, Link } from '@mui/material';
import Navbar from '../components/Navbar';
import Pill from '../components/Pill';
import Jumbotron from '../components/Jumbotron';
import './Courses.css'; 
import ScrollToTopButton from '../components/ScrollToTopButton';
import Footer from '../components/Footer';


const Courses = () => {


  
  const [selectedTag, setSelectedTag] = useState("All");
  
  const handleTagChange = (tag) => {
    // Function to update the selected tag
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    setSelectedTag(tag);

    
  };








  





  return (

    <div 
    style={{
      minHeight: '100vh', // Ensures full viewport height
      backgroundColor: '#faf3e9', // Specific color (in this case, a shade of blue)
      paddingTop: '0px', // Padding to ensure footer doesn't overlap content
      
    }}
  >


<Navbar onTagChange={handleTagChange} />



 
    <Container
      sx={{position: 'relative', bottom: '.5em', top: '1.25em'}}>


    
<Pill 
  sx={{position: 'relative', top: '5em'}}
/>





      <Grid container spacing={1}>
        {/* Header Section */}
        <Grid item xs={12}>
          <Typography variant="h4" align="center" gutterBottom 
           sx={{
            fontSize: '20px', // Custom font size
           
            fontWeight: 'bold', // Custom font weight
            marginTop: '0em', // Custom margin
          }}
          >
            20+ <span className="free-text" style={{borderBottom: '2px solid #fcaa97', marginTop: '3em'}}>Free</span> Online AI Courses
          </Typography>
          <Typography align="center" variant="subtitle1" style={{marginBottom: '2em', marginTop: '2em'}}>
            Provided by
          </Typography>
          
          <Box
    icon-container
    display="flex"
    justifyContent="center"
    gap={2}
    className="icon-horizontal"
    sx={{
      flexWrap: 'wrap',
      '& .course-icon': {
        width: '30px',
        height: '30px',
        margin: '0 5px', // Adjust the horizontal margin between icons
      },
      '@media (max-width: 600px)': {
        '& .course-icon': {
          margin: '0 2px', // Adjust the horizontal margin for smaller screens
        },
      },
    }}
  >
    <img src="Icons/msoft.png" className="course-icon" alt="Microsoft" />
    <img src="Icons/google10.png" className="course-icon" alt="Google" />
    <img src="Icons/amazon.png" className="course-icon" alt="Amazon" />
    <img src="Icons/harvard.png" className="course-icon" alt="Harvard" />
    <img src="Icons/finland.png" className="course-icon" alt="Finland" />
    <img src="Icons/linkedin.png" className="course-icon" alt="LinkedIn" />
    <img src="Icons/zapier.png" className="course-icon" alt="Zapier" />
    <img src="Icons/nvidia.png" className="course-icon" alt="NVIDIA" />
    {/* Add other icons as needed */}
  </Box>
        </Grid>

        {/* Main Content Section */}
        <Grid item xs={12} style={{display: 'grid', justifyContent: 'center', alignContent: 'center', position: 'relative', maxWidth: '1000px', margin: '0 auto'}}>
          <Typography variant="h6" style={{marginTop: '5em', fontSize: '18px', fontWeight: 'bold'}}>Key Statistics:</Typography>
          <ul style={{lineHeight: '1.5'}}>
            <li>The global AI market is currently valued at $150.2 billion and growing</li>
            <li>From 2023 to 2030, the AI market is expected to experience an impressive annual growth rate of 36.8%</li>
            <li>By 2030, the AI market is predicted to expand twentyfold compared to its current size</li>
            <li>Approximately 97 million people are expected to be working in AI-related roles by 2025</li>
            <li>35% of businesses have already adopted AI in some form, leveraging its capabilities to enhance operations and decision-making</li>
            <li>The AI market size is anticipated to reach a staggering $407 billion by 2027</li>
          </ul>
        </Grid>


        {/* Divider */}
        <Grid item xs={12}>
         {/* <hr className="single-divider-1" />*/}
        </Grid>


        {/* Courses Section */}
        <Grid item xs={12}  style={{maxWidth: '1000px', margin: '0 auto'}}>
          
          
          
          
          
          
          
          
          
          {/* Microsoft Courses */}
          
          
          <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: 'bold'}}>
            <img src="Icons/msoft.png" className="course-icon-small2" alt="Microsoft"  style={{width: '30px', height: '30px', marginRight: '10px', position: 'relative', top: '0px'}}/>
            Microsoft Courses
          </Typography>
          <ul>
            <li>
              <Link href="https://microsoft.github.io/AI-For-Beginners/" target="_blank">
                AI For Beginners Curriculum
              </Link>
              <Typography variant="body2" style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>Explore the world of Artificial Intelligence (AI) with Microsoft's 12-week, 24-lesson curriculum! Dive into Symbolic AI, Neural Networks, Computer Vision, Natural Language Processing, and more. Hands-on lessons, quizzes, and labs enhance your learning. Perfect for beginners, this comprehensive guide, designed by experts, covers TensorFlow, PyTorch, and ethical AI principles. Start your AI journey today!</Typography>
            </li>
            {/* Additional Microsoft courses */}
          </ul>

          <ul>
            <li>
            <Link href="https://learn.microsoft.com/en-us/training/paths/transform-your-business-with-microsoft-ai/" target="_blank">              Transform your business with Microsoft AI 
              </Link>
              <Typography variant="body2" style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>This course is designed to close the divide between AI technology and business strategy. This learning path can help business decision makers gain the knowledge to map out critical decisions for their business. Staying on the forefront of the evolving technology behind AI helps business warrant the unlimited potential of artificial intelligence and helps to create continuous value towards innovation.  Topics include AI strategy, culture, responsible AI, ethics, organizational change management, data-driven decision-making, and AI transformation in specific industries.</Typography>
            </li>
            {/* Additional Microsoft courses */}
          </ul>

          <ul>
            <li>
              <Link href="https://learn.microsoft.com/en-us/training/paths/ai-education/" target="_blank">
              AI For Educators
              </Link>
              <Typography variant="body2" style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>This learning path explores the potential of artificial intelligence (AI) in education. It covers a brief history of AI, large language models (LLMs), generative AI, prompt engineering, responsible use of AI, and uses in a class setting. Dive into the art of a prompt to equip learners to get the best results from Microsoft Copilot and Image Creator from Designer. Explore the many ways to enhance teaching and learning with AI-powered tools in Microsoft Edge browser, Word, PowerPoint, Minecraft, and more.</Typography>
            </li>
            {/* Additional Microsoft courses */}
          </ul>

          <ul>
            <li>
              <Link href="https://learn.microsoft.com/en-us/training/paths/ai-toolbox-for-trainers/" target="_blank">
              An AI toolbox for trainers
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>In today's fast-paced digital era, artificial intelligence (AI) is changing the way we train and educate. This learning path is designed to equip you with an understanding of how AI tools can transform a learning environment. Throughout this learning path, you'll explore how AI can elevate the training experience, build engaging content, and foster a more inclusive training environment. You'll also explore the advantages of integrating AI-powered productivity tools into your daily educational practices. By the end of this learning path, you'll be equipped to design and implement training ecosystems that apply AI tools to enhance the training process.</Typography>
            </li>
            {/* Additional Microsoft courses */}
          </ul>











          {/* Google Courses */}
          <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: 'bold', marginTop: '5em'}}>
            <img src="Icons/google10.png" className="course-icon-small" alt="Google" style={{width: '30px', height: '30px', marginRight: '10px', position: 'relative', top: '0px'}}/>
            Google Courses
          </Typography>
          <ul>
            <li>
              <Link href="https://www.cloudskillsboost.google/course_templates/536" target="_blank">
                Introduction to Generative AI
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>This is an introductory level microlearning course aimed at explaining what Generative AI is, how it is used, and how it differs from traditional machine learning methods. It also covers Google Tools to help you develop your own Gen AI apps.</Typography>
            </li>
            {/* Additional Google courses */}
          </ul>


          <ul>
            <li>
              <Link href="https://www.edx.org/learn/artificial-intelligence/google-google-ai-for-anyone" target="_blank">
              Google AI for Anyone
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>Google AI for Anyone teaches you about what Artificial Intelligence is. You’ll cut through the hype and learn about AI and Machine Learning.
                <br/>
                <br/>
                As its name suggests, this course is for anybody -- you don’t need a computer science, mathematics or AI background to understand it. No programming skills or prior knowledge are needed.
                <br/>
                <br/>
                We’ll take you through, from first principles what the fuss is all about, and you’ll get hands-on in playing with data to teach a computer how to recognize images, sounds and more.
                <br/>
                <br/>
                As you explore how AI is used in the real world (recommender systems, computer vision, self-driving etc.) you will also begin to build an understanding of Neural networks and the types of machine learning including supervised, unsupervised, reinforcement etc. You will also see (and experience) what programming AI looks like and how it is applied.
                <br/>
                <br/>
                From here you will be able to continue your journey through the emerging fields of AI and ML and related technologies. In so doing, you will formulate a basis to understand and discuss AI and ML related matters in your personal and professional life.</Typography>
            </li>
            {/* Additional Google courses */}
          </ul>

          

          <ul>
            <li>
              <Link href="https://developers.google.com/machine-learning/crash-course/"  target="_blank">
              Machine Learning Crash Course with TensorFlow APIs
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>Google's fast-paced, practical introduction to machine learning, featuring a series of lessons with video lectures, real-world case studies, and hands-on practice exercises.</Typography>
            </li>
            {/* Additional Google courses */}
          </ul>

          <ul>
            <li>
              <Link href="https://www.thinkwithgoogle.com/intl/en-emea/marketing-strategies/automation/ai-handbook/" target="_blank">
              The AI Handbook: Resources and tools to help marketers get started
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>For marketers, achieving results amid change is hardly a new challenge. But as the industry shifts toward AI, it will be critical to stay current on the latest innovations. We’ll be updating this handbook with new resources and tools as they become available.</Typography>
            </li>
            {/* Additional Google courses */}
          </ul>

          













          {/* Additional Course Sections */}
          <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: 'bold', marginTop: '5em'}}>
            <img src="Icons/amazon.png" className="course-icon-small" alt="Amazon" style={{width: '30px', height: '30px', marginRight: '10px', position: 'relative', top: '0px'}}/>
            Amazon Courses
          </Typography>
          <ul>
            <li>
              <Link href="https://aws.amazon.com/training/learn-about/machine-learning/" target="_blank">
                Machine Learning & Artificial Intelligence
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>This set of on-demand courses will help grow your technical skills and learn how to apply machine learning (ML), artificial intelligence (AI), and deep learning (DL) to unlock new insights and value in your role. Learning Plans can also help prepare you for the AWS Certified Machine Learning – Specialty certification exam.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>












          <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: 'bold', marginTop: '5em'}}>
            <img src="Icons/harvard.png" className="course-icon-small" alt="Harvard" style={{width: '30px', height: '30px', marginRight: '10px', position: 'relative', top: '0px'}}/>
            Harvard University Courses
          </Typography>
          <ul>
            <li>
              <Link href="https://pll.harvard.edu/course/cs50s-introduction-artificial-intelligence-python"  target="_blank">
              CS50's Introduction to Artificial Intelligence with Python
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>CS50’s Introduction to Artificial Intelligence with Python explores the concepts and algorithms at the foundation of modern artificial intelligence, diving into the ideas that give rise to technologies like game-playing engines, handwriting recognition, and machine translation. Through hands-on projects, students gain exposure to the theory behind graph search algorithms, classification, optimization, reinforcement learning, and other topics in artificial intelligence and machine learning as they incorporate them into their own Python programs. 
                  <br /><br />
                  By course’s end, students emerge with experience in libraries for machine learning as well as knowledge of artificial intelligence principles that enable them to design intelligent systems of their own.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>

          <ul>
            <li>
              <Link href="https://pll.harvard.edu/course/machine-learning-and-ai-python" target="_blank">
              Machine Learning and AI with Python
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>It’s time to make a decision: beach or mountains? When choosing where you want to go for vacation, it can be simple. The options may be a or b. From a decision-making standpoint, it’s easy for the brain to process this decision tree. But, what happens when you’re faced with more complex, multifaceted decisions? You might make a comprehensive pro/con list, rank ordering the most important considerations. But, that can take endless amounts of time that you might not have to spare. When parsing through thousands or millions of data points, you and your organization need to tap into a more sophisticated approach.
<br /><br />
The solution? Harnessing the power of artificial intelligence (AI) through machine learning to enhance your decision-making processes. Machine learning with Python can not only help organize data, but machines can also be taught to analyze and learn from disparate data sets – forming hypotheses, creating predictions, and improving decisions.
<br /><br />
In Machine Learning and AI with Python, you will explore the most basic algorithm as a basis for your learning and understanding of machine learning: decision trees. Developing your core skills in machine learning will create the foundation for expanding your knowledge into bagging and random forests, and from there into more complex algorithms like gradient boosting.
<br /><br />
Using real-world cases and sample data sets, you will examine processes, chart your expectations, review the results, and measure the effectiveness of the machine’s techniques.
<br /><br />
Throughout the course, you will witness the evolution of the machine learning models, incorporating additional data and criteria – testing your predictions and analyzing the results along the way to avoid overtraining your data, mitigating overfitting and preventing biased outcomes.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>













          <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: 'bold', marginTop: '5em'}}>
            <img src="Icons/finland.png" className="course-icon-small" alt="Finland" style={{width: '30px', height: '30px', marginRight: '10px', position: 'relative', top: '0px'}}/>
            University of Helsinki Courses
          </Typography>
          <ul>
            <li>
              <Link href="https://course.elementsofai.com/" target="_blank">
              Part One: Introduction to AI
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>An Introduction to AI is a free online course for everyone interested in learning what AI is, what is possible (and not possible) with AI, and how it affects our lives – with no complicated math or programming required.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>


          <ul>
            <li>
              <Link href="https://buildingai.elementsofai.com/"  target="_blank">
              Part Two: Building AI
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>Building AI is a free online course where you’ll learn about the actual algorithms that make creating AI methods possible. Some basic Python programming skills are recommended to get the most out of the course.
                <br/>
                Building AI a flexible online course for anyone who wants to learn about the practical methods that make artificial intelligence a reality. You will get a solid introduction to for example machine learning and neural networks, and you will learn where and how AI methods are applied in real life. It is easy to move freely between the three difficulty levels, from multiple choice exercises to programming with Python – depending on whether you know programming or not.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>













          <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: 'bold', marginTop: '5em'}}>
            <img src="Icons/linkedin.png" className="course-icon-small" alt="LinkedIn" style={{width: '30px', height: '30px', marginRight: '10px', position: 'relative', top: '0px'}}/>
            LinkedIn Courses
          </Typography>
          <ul>
            <li>
              <Link href="https://www.linkedin.com/learning/introduction-to-artificial-intelligence" target="_blank">
              Introduction to Artificial Intelligence
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>Computer scientists are just a small slice of people working in artificial intelligence. Most of the people working in AI are project managers, product managers, directors, and executives. People just like you. This course helps you grasp key concepts in artificial intelligence. You’ll see how AI can enhance your products, life, and career. AI has been around for over half a century. Even with its huge strides, the core ideas in machine learning and neural networks are still accessible.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>

          <ul>
            <li>
              <Link href="https://www.linkedin.com/learning/what-is-generative-ai" target="_blank">
              What is Generative AI?
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>Whether you work in film, marketing, healthcare, automobile, or real-estate, generative AI is changing the way your job is executed, and those who adapt early will reap its benefits sooner. All professions will be affected by generative AI. Its invention can be compared to the invention of photography, a true creative revolution. If you want to be part of the leaders that are advancing this revolution, this course can get you started on your learning journey.
                <br />
                In this course, generative AI expert Pinar Seyhan Demirdag covers the basics of generative AI, with topics including what it is, how it works, how to create your own content, different types of models, future predictions, and ethical implications.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>

          <ul>
            <li>
              <Link href="https://www.linkedin.com/learning/generative-ai-the-evolution-of-thoughtful-online-search" target="_blank">
              Generative AI: The Evolution of Thoughtful Online Search
              </Link>
              <Typography variant="body2">For years, we’ve relied on search engines like Google and Bing to help us navigate the ever-evolving internet. But now that reasoning engines like Chat-GPT have come onto the scene, how can you prepare for the future of online search? In this course, instructor Ashley Kennedy shows you how to get up to speed with the key distinctions and best practices of using reasoning engines that leverage generative AI.
                <br />
                Learn more about the core concepts of generative AI-driven reasoning engines and how they differ from search engine strategy. Along the way, Ashley interviews subject matter experts and offers easy-to-follow advice on how to harness the power of prompt engineering with thoughtful search strategies that help account for the nuances of language, tone, qualifiers, and more.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>

          <ul>
            <li>
              <Link href="https://www.linkedin.com/learning/ethics-in-the-age-of-generative-ai"  target="_blank">
              Ethics in the Age of Generative AI
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>The amazing potential and opportunity of Generative AI has also brought ethical concerns. How can you address these concerns effectively as you deploy tools and products powered by generative AI? In this course, expert Vilas Dhar equips designers and decision makers to better understand and act on the emerging ethical risks and considerations of generative artificial intelligence. Get a solid introduction to the ethical concerns around generative AI, then learn how to use a framework of ethical analysis to address these concerns. Plus, explore ways you can prepare your organization to address ethics in AI across all stakeholders from technology to C-Suite leaders.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>
















          <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: 'bold', marginTop: '5em'}}> 
            <img src="Icons/zapier.png" className="course-icon-small" alt="Zapier" style={{width: '30px', height: '30px', marginRight: '10px', position: 'relative', top: '0px'}}/>
            Zapier Courses
          </Typography>
          <ul>
            <li>
              <Link href="https://learn.zapier.com/increase-productivity-using-ai" target="_blank">
              Increase productivity using AI
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>This course introduces you to powerful generative AI tools, like OpenAI's ChatGPT and Zapier Chatbots, and showcases ways to use them in your work. With the right approach, AI can help you automate tasks and achieve remarkable efficiency, speed, and scalability.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>



















          <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: 'bold', marginTop: '5em'}}>
            <img src="Icons/nvidia.png" className="course-icon-small" alt="Nvidia" style={{width: '30px', height: '30px', marginRight: '10px', position: 'relative', top: '0px'}}/>
            Nvidia Courses
          </Typography>
          <ul>
            <li>
              <Link href="https://learn.nvidia.com/courses/course-detail?course_id=course-v1:DLI+S-FX-07+V1" target="_blank">
              Generative AI explained
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>Generative AI describes technologies that are used to generate new content based on a variety of inputs. In recent time, Generative AI involves the use of neural networks to identify patterns and structures within existing data to generate new content. In this course, you will learn Generative AI concepts, applications, as well as the challenges and opportunities in this exciting field.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>

          <ul>
            <li>
              <Link href="https://learn.nvidia.com/courses/course-detail?course_id=course-v1:DLI+T-FX-01+V1" target="_blank">
              Build a Brain in 10 Minutes 
              </Link>
              <Typography variant="body2"  style={{marginTop: '5px', fontSize: '16px', lineHeight: '1.25', marginBottom: '30px'}}>This notebook explores the biological and psychological inspirations to the world's first neural networks.</Typography>
            </li>
            {/* Additional Amazon courses */}
          </ul>












          {/* Other courses (LinkedIn, Harvard, etc.) can be added similarly */}
        </Grid>
      </Grid>
    </Container>
    <ScrollToTopButton />
    <Footer />
</div>
    
  );
};

export default Courses;
