import logo from './logo.svg';
import './App.css';
import { React } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage'; // Import the homepage
import Footer from './components/Footer';
import theme from './components/CreateTheme.js'; // Import your custom theme
import { ThemeProvider } from '@mui/material/styles';
import ScrollToTop from './components/ScrollToTop';
import { Outlet } from 'react-router-dom';
import Courses from './pages/Courses.js';
import { useState } from 'react';
import Timeline from './pages/Timeline.js';
import Countdown from './pages/Countdown.js';
import Glossary from './pages/Glossary.js';

import Society from './pages/Society.js';
import TrueCrime from './pages/TrueCrime.js';
import Celebrities from './pages/Celebrities.js';
import News from './pages/News.js';
import Politics from './pages/Politics.js';
import Relationships from './pages/Relationships.js';
import Mental from './pages/Mental.js';

import Popup from './components/Popup.js';

import Landing from './pages/Landing.js';


import TopTen from './pages/TopTen.js';
import Navbar from './/components/Navbar.js';
import Featured from './pages/Featured.js';
import Popular from './pages/Popular.js'

import Business from './pages/Business.js';
import Health from './pages/Health.js';
import Education from './pages/Education.js';
import History from './pages/History.js';
import Games from './pages/Games.js';
import Music from './pages/Music.js';
import Technology from './pages/Technology.js';
import Entr from './pages/Entr.js';
import SocialMedia from './pages/SocialMedia.js';
import Science from './pages/Science.js';
import TV from './pages/TV.js';
import Finance from './pages/Finance.js';
import AI from './pages/AI.js';
import Stars from './pages/Stars.js';

import Comedy from './pages/Comedy.js';
import Sports from './pages/Sports.js';
import Arts from './pages/Arts.js';
import Food from './pages/Food.js';
import Lifestyle from './pages/Lifestyle.js';

import SelfImprovement from './pages/SelfImprovement.js';

import Criminal from './pages/Criminal.js';
import Spy from './pages/Spy.js';
import Horror from './pages/Horror.js';

import Police from './pages/Police.js';


import Disaster from './pages/Disaster.js';
import FoodAndCooking from './pages/FoodAndCooking.js';
import Historical from './pages/Historical.js';
import Religion from './pages/Religion.js';
import Paranormal from './pages/Paranormal.js';
import Prison from './pages/Prison.js';
import Survival from './pages/Survival.js';
import PodcastPage from './pages/PodcastPage';
import data from './data.json'; // Import your JSON data
import Topbar from './components/Topbar.js';

import Outdoors from './pages/Outdoors.js';
import Cars from './pages/Cars.js';
import F1 from './pages/F1.js';
import Scams from './pages/Scams.js';
import ContactForm from './pages/ContactForm.js';
import Wondery from './pages/Wondery.js';
import War from './pages/War.js';

import Newsletter from './pages/Newsletter.js';
import Aiforpodcasts from './pages/Aiforpodcasts.js';
import Parcast from './pages/Parcast.js';
import Rogan from './pages/Rogan.js';



function App() {
  
  return (
    <Router>
      <ScrollToTop />
      
        <ThemeProvider theme={theme}>
       {/* <Popup /> */}

    <Routes>
  
      <Route path="/" element={<HomePage />} /> {/* Homepage */}

      <Route path='/society' element={<Society />} />
      <Route path='/truecrime' element={<TrueCrime />} />
      <Route path='/celebrities' element={<Celebrities />} />
      <Route path='/news' element={<News />} />
      <Route path='/politics' element={<Politics />} />
      <Route path='/relationships' element={<Relationships />} />
      <Route path='/mental' element={<Mental />} />
      <Route path='/prison' element={<Prison />} />
    
      <Route path='/aiforpodcasts' element={<Aiforpodcasts />} />

      <Route path='/topten' element={<TopTen />} />
      <Route path='/featured' element={<Featured />} />
      <Route path='/popular' element={<Popular />} />
      <Route path='/stars' element={<Stars />} />

      <Route path='/landing' element={<Landing />} />
      <Route path='/newsletter' element={<Newsletter />} />
      <Route path='/business' element={<Business />} />
      <Route path='/health' element={<Health />} />
      <Route path='/education' element={<Education />} />
      <Route path='/history' element={<History />} />
      <Route path='/outdoors' element={<Outdoors />} />
      <Route path='/police' element={<Police />} />

      <Route path='/games' element={<Games />} />
      <Route path='/music' element={<Music />} />
      <Route path='/technology' element={<Technology />} />

      <Route path='/entr' element={<Entr />} />
      <Route path='/socialmedia' element={<SocialMedia />} />
      <Route path='/science' element={<Science />} />

      <Route path='/wondery' element={<Wondery />} />
      <Route path='/parcast' element={<Parcast />} />


      <Route path='/tv' element={<TV />} />
      <Route path='/finance' element={<Finance />} />
      
      <Route path='/disaster' element={<Disaster />} />

      <Route path='/comedy' element={<Comedy />} />
      <Route path='/sports' element={<Sports />} />
      <Route path='/arts' element={<Arts />} />
      <Route path='/food' element={<Food />} />
      <Route path='/lifestyle' element={<Lifestyle />} />

      <Route path='/selfimprovement' element={<SelfImprovement />} />
    
      <Route path='/foodandcooking' element={<FoodAndCooking />} />
      <Route path='/historical' element={<Historical />} />
      <Route path='/religion' element={<Religion />} />

      <Route path='/survival' element={<Survival />} />
      <Route path='/paranormal' element={<Paranormal />} />

      <Route path="/podcast/:id" element={<PodcastPage />} />
      <Route path='/rogan' element={<Rogan />} />

      <Route path='/spy' element={<Spy />} />
      <Route path='/criminal' element={<Criminal />} />

      <Route path='/ai' element={<AI />} />
      <Route path='/cars' element={<Cars />} />
      <Route path='/f1' element={<F1 />} />
      <Route path='/scams' element={<Scams />} />
      <Route path='/horror' element={<Horror />} />
      <Route path='/war' element={<War />} />

      <Route path="/timeline" element={<Timeline/>} />
      <Route path="/countdown" element={<Countdown />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/glossary" element={<Glossary />} />
      <Route path="/contact" element={<ContactForm />} />
    </Routes>
    </ThemeProvider>
  </Router>
  
  );
}

export default App;
