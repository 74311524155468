import React, { useState, useEffect } from 'react';

import './Timeline.css';
import jsonData from '../data.json';
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import Pill from '../components/Pill';
import Container from '../components/Container';
import Footer from '../components/Footer';
import ScrollToTopButton from '../components/ScrollToTopButton';
import { Link } from 'react-router-dom';

const Timeline = () => {


  const [selectedTag, setSelectedTag] = useState("All");
  
  const handleTagChange = (tag) => {
    // Function to update the selected tag
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    setSelectedTag(tag);

    
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount or update
  }, []);



  return (
    <div
    style={{
      minHeight: '100vh', // Ensures full viewport height
      backgroundColor: '#f7f1e3', // Specific color (in this case, a shade of blue)
      paddingBottom: '0px', // Padding to ensure footer doesn't overlap content
      
    }}
  > <Navbar onTagChange={handleTagChange} />
  
  <Container
      sx={{position: 'relative', bottom: '.0em', top: '0em'}}>
  
  
    
    
    



    <div className="dark-text-timeline">
    <Pill />
 
    <h1 id="single-guide-header6" style={{color: '#333', marginTop: '1em'}}>Tracking the Evolution of Podcasts: 2004 to Present</h1>
      
      <div className="dots" style={{marginLeft: '15px', marginBottom: '3em'}}>
        <li className="green"></li>
        <li className="blue"></li>
        <li className="red"></li>
        <li className="aqua"></li>
        <li className="orange"></li>
      </div>
    <div style={{backgroundColor: '#e7e1d1', borderRadius: '12px', paddingTop: '1px', paddingBottom: '2em', padding: '40px', maxWidth: '900px', margin: '0 auto', boxShadow: '0px 1px 3px #666'}}>

    <h6 id="course-subheader-6" style={{color: '#333', marginTop: '0em'}}>
      Key Milestones in the History and Evolution of Podcasts
    </h6>
    <h6 className="course-subheader-22" style={{maxWidth: '1000px', margin: '0 auto', color: '#333', fontSize: '14px'}}>


    
    This timeline serves as a testament to the resilience and adaptability of podcasting, showcasing its journey from humble beginnings to becoming a dominant force in the modern media landscape.
    </h6>

    <div className="timeline-container">
      <div className="timeline">
        <div className="container-time left circle-green">
          <div className="content">
            <h2>2004</h2>
            <p style={{fontSize: '14px'}}>
            Adam Curry and Dave Winer invent podcasting. 
            <br/><br/>
            The term “podcast” is first used by Ben Hammersley in his Guardian article, being a mix of the words 'iPod' (a popular mp3 player at the time) and 'broadcast' (radio, tv).
            </p>
          </div>
        </div>
        <div className="container-time right circle-blue">
          <div className="content">
            <h2>2006</h2>
            <p style={{fontSize: '14px'}}>
            Ricky Gervais sets a Guinness World Record for the most downloaded podcast at 261,670 per episode.
            <br/><br/>
            In a keynote speech, Apple CEO Steve Jobs demonstrates how to make a podcast using Garageband.


            </p>
          </div>
        </div>
        <div className="container-time left circle-red">
          <div class="content">
            <h2>2013</h2>
            <p style={{fontSize: '14px'}}>
            Apple announces one billion podcast subscribers are using its platform.


            </p>
          </div>
        </div>
        <div className="container-time right circle-yellow">
          <div class="content">
            <h2>2015</h2>
            <p style={{fontSize: '14px'}}>
            Serial is the first podcast to win a Peabody Award.
            <br/><br/>
            Marc Maron interviews President Barack Obama on his podcast, WTF with Marc Maron.
            </p>
          </div>
        </div>
        <div className="container-time left circle-purple">
          <div class="content">
            <h2>2019</h2>
            <p style={{fontSize: '14px'}}>
            Music streaming giant Spotify acquires podcast networks Gimlet Media and Anchor FM in a $340 million deal, establishing Spotify as a major player in the podcasting space. 


            </p>
          </div>
        </div>
        <div className="container-time right circle-orange">
          <div class="content">
            <h2>2020</h2>
            <p style={{fontSize: '14px'}}>
            The COVID-19 pandemic led to a surge in podcast consumption as millions of people worldwide sought entertainment and information while staying at home. This unprecedented demand for podcasts highlighted the medium's resilience and adaptability in times of crisis.


            </p>
          </div>
        </div>



        <div className="container-time left circle-pink">
          <div class="content">
            <h2>2021</h2>
            <p style={{fontSize: '14px'}}>
              Major podcasters and celebrities signed exclusive deals with platforms like Spotify and Apple Podcasts, ushering in a new era of podcasting economics. 
              <br/><br/>
              Notable deals included Joe Rogan's exclusive licensing agreement with Spotify and Oprah Winfrey's partnership with Apple Podcasts.

            </p>
          </div>
        </div>
        <div className="container-time right circle-green">
          <div class="content">
            <h2>2022</h2>
            <p style={{fontSize: '14px'}}>
            Podcast advertising revenue reached record highs in 2022, surpassing $1 billion for the first time. This milestone underscored the increasing appeal of podcasts as a lucrative advertising platform for brands seeking to reach engaged audiences.
            <br/><br/>
Twitter announces they’ll be integrating podcasts into the Spaces feature.
<br/><br/>
YouTube releases a podcast destination page.
<br/><br/>
True-crime podcasts remain a dominant genre, with some shows influencing documentaries, TV adaptations, and cultural conversations 
<br/><br/>
Podcast listenership continues to climb, with estimates suggesting over 200 million monthly listeners in the US alone
            </p>
          </div>
        </div>


        <div className="container-time left circle-pink">
          <div class="content">
            <h2>2023</h2>
            <p style={{fontSize: '14px'}}>
            Podcasting experienced significant growth in international markets, with countries like India, Brazil, and China emerging as key hubs for podcast consumption and production. This global expansion highlighted the universal appeal of podcasts across diverse cultures and languages.
            <br/><br/>
            Integration with smart speakers like Amazon Echo and Google Home becomes widespread, offering seamless podcast access through voice commands.
            <br/><br/>
            Educational podcasts grow in popularity, providing engaging learning experiences for various audiences.
            </p>
          </div>
        </div>
</div>
      
        </div>
      </div>
    </div>
    <Link to="/" style={{textDecoration: 'none'}}>

<button className="home-button" style={{ fontFamily: "Bricolage Grotesque", marginTop: '2em'}}>Go to Home</button>


    </Link>

    <Footer />
    </Container>
    <ScrollToTopButton />
   
    </div>
  );
};

export default Timeline;