import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import CardList from '../components/CardList';
import Footer from '../components/Footer';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import './HomePage.css'; 
import data from '../data.json';
import CancelIcon from '@mui/icons-material/Cancel'; // Import the cancel icon
import Chip from '@mui/material/Chip';
import Cookies from '../components/Cookies';
import Courses from './Courses';
import AITimeline from './Timeline';
import CountdownToAGI from './Countdown';
import Glossary from './Glossary';
import ScrollToTopButton from '../components/ScrollToTopButton'; 
import Pill from '../components/Pill';

const Stars = () => {
  const [showJumbotron, setShowJumbotron] = useState(true); // State to control Jumbotron visibility
  const [selectedTag, setSelectedTag] = useState("All");
  
  const handleTagChange = (tag) => {
    // Function to update the selected tag
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    setSelectedTag(tag);
  };

  const clearTag = () => {
    setSelectedTag("All"); // Reset to "All"
  };

  useEffect(() => {
    console.log("HomePage re-rendered"); // Ensure re-render is happening
  }, [selectedTag]); // Trigger re-render when selectedTag changes

  // Filter tools based on the selected tag
  const filteredTools = selectedTag === "All"
    ? data.tools
    : data.tools.filter(tool => tool.tags && tool.tags.includes(selectedTag));
    
  const onClearTag = () => {
    setSelectedTag("All"); // Reset to all tools
  };

  const resetToDefault = () => {
    setSelectedTag("All"); // Reset the tag to "All"
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top smoothly
  };

  return (
    <div
      style={{
        minHeight: '100vh', // Ensures full viewport height
        backgroundColor: '#333', // Specific color (in this case, a shade of blue)
        paddingBottom: '0px', // Padding to ensure footer doesn't overlap content
        fontSize: '14px'
      }}
    >
      <Navbar onTagChange={handleTagChange} onLogoClick={resetToDefault} />
      
      {/* Constrained content area */}
      <Container onTagChange={handleTagChange} maxWidth={false} style={{ maxWidth: '700px', margin: '0 auto', padding: '20px' }}>
        <Pill />
        <div style={{color: '#e758d6', fontSize: '34px', fontWeight: 'bold', display: 'flex', textAlign: 'center', justifyContent: 'center', marginTop: '2em' }}>
          Coming Soon!
        </div>
        <div style={{ backgroundColor: '#444', borderRadius: '12px', padding: '20px', paddingTop: '0px', marginTop: '0em' }}>
        <div style={{display: 'flex', justifyContent: 'center', color: 'white', marginTop: '2em'}}>
          <p style={{color: '#ddd', textAlign: 'center', marginTop: '30px'}}>
            Welcome to the Rising Stars section, where we shine a spotlight on the most promising new podcasters.
          </p>
        </div>
        <div style={{display: 'flex', marginTop: '2em', color: 'white'}}>
          <div style={{flex: 1, marginRight: '20px'}}>
            <p style={{color: '#ddd'}}>
              <br/>
              <br />
              This exclusive feature will soon showcase a list of up-and-coming podcasts, highlighting the talent and creativity emerging from our community.
            </p>
          </div>
          <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src="/studio.png" alt="Studio" style={{ border: '2px solid #222' , maxWidth: '100%', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', color: 'white', marginTop: '2em'}}>
          <p style={{color: '#ddd'}}>
            <p style={{color: '#ddd', fontSize: '16px', marginBottom: '0px', textAlign: 'center'}}>As a Rising Star, you'll benefit from:</p>
            <br />
            <ul style={{textAlign: 'left', lineHeight: 1.25}}>
              
              <li style={{marginTop: '15px'}}>Top Charts Feature: Your podcast will be featured in our Rising Stars section, providing even greater visibility and promotion. This puts your podcast on our Top Charts list and easily accessible across the site.</li>
              
              <li style={{marginTop: '15px'}}>Community Recognition: Join a select group of podcasters recognized for their quality and potential.</li>
            </ul>
            <br />
            <p style={{textAlign: 'center', color: '#ddd', marginBottom: '4em'}}>
            Stay tuned for the launch of our Rising Stars section and get ready to discover your next favorite podcast, or become the next popular podcast yourself!</p>
           
          </p>
        </div>
        </div>
        <ScrollToTopButton />
      </Container>
      <Footer />
    </div>
  );
};

export default Stars;

