import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Box, Link } from '@mui/material';
import Navbar from '../components/Navbar';
import Pill from '../components/Pill';
import Jumbotron from '../components/Jumbotron';
import './Courses.css'; 
import ScrollToTopButton from '../components/ScrollToTopButton';
import Footer from '../components/Footer';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';



const Courses = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount or update
  }, []);
  
  const [selectedTag, setSelectedTag] = useState("All");
  
  const handleTagChange = (tag) => {
    // Function to update the selected tag
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    setSelectedTag(tag);

    
  };








  





  return (

    <div 
    style={{
      minHeight: '100vh', // Ensures full viewport height
      backgroundColor: '#f7f1e3', // Specific color (in this case, a shade of blue)
      paddingTop: '0px', // Padding to ensure footer doesn't overlap content
      
    }}
  >


<Navbar onTagChange={handleTagChange} />



 
    <Container
      sx={{position: 'relative', bottom: '.5em', top: '1.25em', maxWidth: '1290px'}}>


    
<Pill 
  sx={{position: 'relative', top: '5em'}}
/>





      <Grid container spacing={1}>
        {/* Header Section */}
        <Grid item xs={12}>
          <Typography variant="h4" align="center" gutterBottom 
           sx={{
            fontSize: '20px', // Custom font size
            color: '#333',
            fontWeight: '500', // Custom font weight
            marginTop: '0em', // Custom margin
          }}
          >
            Discover the World of Podcasts: Your New Favorite Way to Learn and Be Entertained <span className="free-text" style={{borderBottom: '2px solid #fcaa97', marginTop: '3em'}}></span> 
          </Typography>
          <Typography align="center" variant="subtitle1" style={{marginBottom: '2em', marginTop: '2em', color: '#333', fontSize: '14px'}}>
            Listen on:
          </Typography>
          
          <Box
    icon-container
    display="flex"
    justifyContent="center"
    gap={2}
    className="icon-horizontal"
    sx={{
      marginBottom: '3em',
      flexWrap: 'wrap',
      '& .course-icon': {
        width: '25px',
        height: '25px',
        margin: '0 5px', // Adjust the horizontal margin between icons
      
      },
      '@media (max-width: 600px)': {
        '& .course-icon': {
          margin: '0 2px', // Adjust the horizontal margin for smaller screens
        },
      },
    }}
  >
    <Tooltip title="Spotify"><img src="/spotify.png" className="course-icon"  /></Tooltip>
    <Tooltip title="Apple"><img src="/apple1.png" className="course-icon" /></Tooltip>
    <Tooltip title="Castbox"><img src="/castbox.png" className="course-icon" /></Tooltip>
    <Tooltip title="AntennaPod"><img src="/antenna.png" className="course-icon"  /></Tooltip>
    <Tooltip title="Podcast Addict"><img src="/addict.png" className="course-icon"  /></Tooltip>
    <Tooltip title="Pocket Casts"><img src="/pocketcast.svg" className="course-icon" /></Tooltip>
   
    
    <Tooltip title="Podbean"><img src="/bean.png" className="course-icon"  /></Tooltip>
    <Tooltip title="Overcast"><img src="/overcast.png" className="course-icon" /></Tooltip>
    {/* Add other icons as needed */}
  </Box>
        </Grid>
<div style={{backgroundColor: '#e7e1d1', borderRadius: '12px', padding: '40px', maxWidth: '900px', margin: '0 auto', boxShadow: '0px 1px 3px #666'}}>
        {/* Main Content Section */}
        <Grid item xs={12} style={{display: 'grid', justifyContent: 'center', alignContent: 'center', position: 'relative', maxWidth: '1000px', margin: '0 auto'}}>
          <Typography variant="h6" style={{marginTop: '1em', fontSize: '18px', fontWeight: '500', color: '#333'}}>Key Statistics:</Typography>
          <ul style={{lineHeight: '1.5', color: '#333', marginBottom: '0em'}}>
      
            <li style={{fontSize: '14px'}}>As of 2023, over 2 billion people listen to podcasts monthly worldwide.</li>
            <li style={{marginTop: '.5em', fontSize: '14px' }}>Listeners consume an average of 5 episodes per week, totaling over 17 hours of content.</li>
            <li style={{marginTop: '.5em', fontSize: '14px'}}>There are over 4 million podcasts and over 180 million episodes available.</li>
            <li style={{marginTop: '.5em', fontSize: '14px'}}>78% of podcast listeners finish an entire episode upon listening.</li>
            <li style={{marginTop: '.5em', fontSize: '14px'}}>Studies suggest that listening to podcasts can improve memory. A 2019 study from University College London found that participants who listened to podcasts performed better on memory tests compared to those who listened to music.</li>
            <li style={{marginTop: '.5em', fontSize: '14px'}}>The podcast industry shows no signs of slowing down. The global podcast revenue is expected to reach a staggering $40 billion by 2025 [Source: Markets and Markets]. This growth signifies a vibrant and dynamic medium with a promising future.</li>
            
          </ul>
        </Grid>


        {/* Divider */}
        <Grid item xs={12}>
         {/* <hr className="single-divider-1" />*/}
        </Grid>


        {/* Courses Section */}
        <Grid item xs={12}  style={{maxWidth: '1000px', margin: '0 auto', marginTop: '7em'}}>
          
          
          
          
          
       
          
          
          
          {/* Microsoft Courses */}
          
          
          <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: '500', color: '#333'}}>
           
          What is a podcast?

          </Typography>
          <ul>
            
            
              <Typography variant="body2" style={{marginTop: '5px', fontSize: '14px', marginBottom: '70px', color: '#333'}}>
                A podcast is like a radio show that you can listen to anytime, anywhere. It's an audio program that you can stream or download to your phone, tablet, or computer, allowing you to enjoy it online or offline. The format typically involves a host (or several) interviewing guests or deep-diving into interesting topics or events. Whether you're commuting, working out, cooking, or just relaxing at home, there's a podcast for every moment.</Typography>
            
            {/* Additional Microsoft courses */}
          </ul>



          <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: '500', color: '#333'}}>
           
           How do I listen to a podcast?
         </Typography>
         <ul>
          
           
             <Typography variant="body2" style={{marginTop: '5px', fontSize: '14px', marginBottom: '25px', color: '#333'}}>
             <p>Getting started with podcasts is easy:</p></Typography>
              <li style={{color: '#333', lineHeight: '1.5' , marginBottom: '70px'}}>
             <li style={{fontSize: '14px'}}>Download a Podcast App: apps like Apple Podcasts, Spotify, and Pocket Casts are popular choices. </li>
             <li  style={{fontSize: '14px'}}>Browse and Subscribe: once you have an app, you can browse through different categories, subscribe to your favorites, and download episodes to listen to offline. </li>
              <li  style={{fontSize: '14px'}}>Listen Anytime, Anywhere: podcasts can be listened to on your phone, computer, in your car, at home or wherever you are.</li>
           </li>
           {/* Additional Microsoft courses */}
         </ul>



         <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: '500', color: '#333', }}>
           
         What are podcasts about?
         </Typography>
         <ul>
          
           
             <Typography variant="body2" style={{marginTop: '0px', fontSize: '14px', marginBottom: '25px', color: '#333'}}>
             <p>Podcasts cover a wide range of topics and interests. Some popular genres that you might enjoy are:</p></Typography>
              <li style={{color: '#333', lineHeight: '1.5' , marginBottom: '70px', fontSize: '14px'}}>
             <li  style={{fontSize: '14px'}}>True Crime: Dive into real-life mysteries, investigations, and crime stories that keep you on the edge of your seat.</li>
             <li  style={{fontSize: '14px'}}>Celebrities: Many celebrities have started their own podcasts where they interview other celebrities or interesting people.</li>
             <li style={{fontSize: '14px'}}>News and Politics: Stay informed with daily news updates, in-depth analysis, and discussions on current events.</li>
             <li style={{fontSize: '14px'}}>Health and Wellness: Get tips on fitness, mental health, nutrition, and overall well-being from experts in the field.</li>
             <li style={{fontSize: '14px'}}>Business and Technology: Learn about the latest in business trends, startups, tech innovations, and career advice.</li>
             <li style={{fontSize: '14px'}}>History: Explore fascinating historical events, figures, and stories from different eras and cultures.</li>
             <li style={{fontSize: '14px'}}>Arts and Entertainment: Enjoy reviews, interviews, and discussions about movies, music, books, and more.</li>
             <li style={{fontSize: '14px'}}>Education: Expand your knowledge with educational content on science, language learning, and other academic subjects.</li>
             <li style={{fontSize: '14px'}}>Personal Development: Get inspired with motivational talks, self-improvement tips, and life coaching advice.</li>
           
           </li>
           {/* Additional Microsoft courses */}
         </ul>



         <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: '500', color: '#333'}}>
           
         What Can You Learn from Podcasts?
         </Typography>
         <ul>
          
           
             <Typography variant="body2" style={{marginTop: '5px', fontSize: '14px', marginBottom: '25px', color: '#333'}}>
             <p>Podcasts are an incredible resource for both learning and entertainment. Depending on the genre, you can:</p></Typography>
              <li style={{color: '#333', lineHeight: '1.5' , marginBottom: '70px', fontSize: '14px'}}>
             <li style={{fontSize: '14px'}}>Stay Informed: Keep up with the latest news, trends, and developments in various fields. </li>
             <li style={{fontSize: '14px'}}>Learn New Skills: Pick up new hobbies, improve your knowledge, and gain valuable insights.</li>
             <li style={{fontSize: '14px'}}>Get Inspired: Hear motivational stories, personal journeys, and expert advice.</li>
             <li style={{fontSize: '14px'}}>Relax and Enjoy: Escape into captivating stories, humor, and engaging discussions.</li>
           </li>
           {/* Additional Microsoft courses */}
         </ul>




         <Typography variant="h6" className="course-company" style={{fontSize: '18px', fontWeight: '500', color: '#333'}}>
           
         Discovering the Best Podcasts with Our Library

           </Typography>
           <ul>
            
             
               <Typography variant="body2" style={{marginTop: '0px', fontSize: '14px', marginBottom: '25px', color: '#333'}}>
               <p>With so many podcasts available, finding the best ones can be overwhelming. That’s where our site comes in:</p></Typography>
                <li style={{color: '#333', lineHeight: '1.5', fontSize: '14px'}}>
               <li style={{fontSize: '14px'}}>We’ve curated a collection of the best podcasts across various genres.</li>
               <li style={{fontSize: '14px'}}>Our user-friendly site helps you quickly find podcasts that match your interests.</li>
               <li style={{fontSize: '14px'}}>Our library is constantly updated with new and trending podcasts to keep your listening experience fresh and exciting.</li>
               <br />
               By using our site, you'll save time and effort, allowing you to dive straight into the best content available. Whether you're looking for the latest news, a good laugh, or a gripping story, you'll find it here. Start exploring today and discover the wonderful world of podcasts!
             </li>
            
           </ul>





        </Grid>
        </div>
        
      </Grid>
     
     
      <Footer />
    </Container>
    <ScrollToTopButton />

</div>
    
  );
};

export default Courses;
