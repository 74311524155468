import React, { useEffect, useState } from 'react';
import { Box, Typography, Chip, useMediaQuery } from '@mui/material';
import { IconButton } from '@mui/material';
import './Jumbotron.css'
import Avatar from '@mui/material/Avatar';
import { useLocation } from 'react-router-dom'; // Assuming you're using React Router


const smallAvatarStyle = {
  width: 22, // Set custom width
  height: 22, // Set custom height
  fontSize: 12, // Adjust font size for text-based avatars
};

const Jumbotron = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [showJumbotron, setShowJumbotron] = useState(!isSmallScreen);
  const location = useLocation();


  useEffect(() => {
    // Show Jumbotron only on homepage if it's a small screen
    setShowJumbotron(isSmallScreen && location.pathname === '/');
  }, [isSmallScreen, location]);


 
  return (
    
    <>
         {(isSmallScreen && location.pathname === '/') || !isSmallScreen ? (
    
    <Box sx={{ position: 'relative', bottom: '20px', padding: '2rem', textAlign: 'center', backgroundColor: '#f7f1e3', color: '#eee', marginBottom: '2.5em' }}>
     
      

      <Typography variant="h2" id="highlighted-text"
      style={{ textTransform: 'none', marginBottom: '0px', fontWeight: 'bold', fontSize: '50px', marginTop: '0px',  color: '#2C3E50', marginBottom: isSmallScreen ? '50px' : '30px'}}
      
      >
        Discover Your Next Favorite Podcast<span className="highlight8"></span>

       {/* Discover Your Next Favorite <span className="highlight8">Podcast</span> */}</Typography>
      <Typography variant="subtitle1"
      style={{ textTransform: 'none', marginBottom: '20px' , color: '#2C3E50', fontSize: '16px', maxHeight: '2em', marginBottom: isSmallScreen ? '200px' : '0px'}}
      >

With millions of podcasts across several platforms, we make it easy to find chart-topping shows and undiscovered gems. Hear extraordinary stories from survivors, cops, veterans, and spies. 
Laugh with comedians, learn from experts and connect with your favorite celebrities.
 We're your guide to the podcast world's treasures, big and small. 
</Typography>
    </Box>
     ) : null}
     </>
  );
};{/*We scour the internet for the best and most-recommended podcasts so you can spend less time searching and more time listening.*/}

export default Jumbotron;


/*Get the inside track on the podcast world delivered fresh to your inbox every week. We'll keep you in the loop with the hottest industry news, introduce you to must-hear shows 
and spotlight episodes that have everyone talking. */


{/*Easily find chart-topping shows and undiscovered gems. Hear extraordinary stories from survivors, cops, veterans, and spies. 
Laugh with comedians, learn from experts, and connect with your favorite celebrities.
 We're your guide to the podcast world's treasures, big and small. */}
          {/*Tune in anywhere, anytime and enjoy the  the ever-expanding universe of audio entertainment.
        {/*With millions of free podcasts across several platforms, we make it easy to find your next obsession. From celebrity-hosted interviews and expert insights to captivating stories from everyday people, there's a podcast for every interest. {/*Tune in anywhere, anytime and enjoy the  the ever-expanding universe of audio entertainment.
        
        
        
        
        
        
        Get the inside scoop on everything podcasts: from breaking news and industry gossip to chart-toppers and undiscovered gems. Our free weekly newsletter is your VIP pass to staying informed in the podcast universe.
        
        
        */}