import React, { useState, useEffect } from 'react';

import './Glossary.css'; // Import your CSS file for page styling
import Jumbotron from '../components/Jumbotron';
import Pill from '../components/Pill';
import Container from '../components/Container';
import Navbar from '../components/Navbar';
import ScrollToTopButton from '../components/ScrollToTopButton';
import Footer from '../components/Footer';

const Glossary = () => {


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount or update
  }, []);
  
  const [selectedTag, setSelectedTag] = useState("All");
  
  const handleTagChange = (tag) => {
    // Function to update the selected tag
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    setSelectedTag(tag);

    
  };






  return (



    <div
    style={{
      minHeight: '100vh', // Ensures full viewport height
      backgroundColor: '#f7f1e3', // Specific color (in this case, a shade of blue)
      paddingBottom: '0px', // Padding to ensure footer doesn't overlap content
      
    }}
  >
   <Navbar onTagChange={handleTagChange} />
    <Container style={{maxWidth: '1290px'}}>
 


  

    <div className="glossary-container" >
    <Pill />
      <div className="glossary-header">
      
        <h1 id="single-guide-header" style={{color: '#333'}}>Glossary</h1>
        <div className="dots" style={{marginLeft: '15px', marginBottom: '3em'}}>
          <li className="green"></li>
          <li className="blue"></li>
          <li className="red"></li>
          <li className="aqua"></li>
          <li className="orange"></li>
        </div>
        </div>
        <div style={{ display: 'grid', justifyContent: 'center', backgroundColor: '#e7e1d1', borderRadius: '12px', padding: '10px', paddingTop: '1px', maxWidth: '1000px', margin: '0 auto', boxShadow: '0px 1px 3px #666'}}>
        <h6 className="course-subheader-7" style={{color: '#333'}}>10 Podcast terms everyone should know</h6>
     

      <div className="glossary-content">
        <h6 className="course-subheader-2" style={{color: '#333', marginBottom: '5em', fontSize: '14px', marginTop: '0'}}>
        
In recent years, podcasts have surged in popularity, emerging as a captivating and accessible form of entertainment, education, and storytelling. With millions of episodes covering virtually every topic imaginable, podcasts offer listeners a diverse array of content to explore and enjoy. <br /><br />

As the podcasting landscape continues to evolve and expand, understanding key terms within the industry becomes increasingly valuable for listeners and potential creators. Familiarity with these terms equips listeners with some basic knowledge to get started.<br /><br />



        </h6>
        <ul style={{color: '#333'}}>
        <li className='green' style={{fontSize: '14px'}}><span className='glossary-word' style={{color: 'black'}}>Episode: </span>An individual audio recording within a podcast series. Each episode typically covers a specific topic or features a particular guest.</li>
            <br />
            <li className='blue' style={{fontSize: '14px'}}><span className='glossary-word' style={{color: 'black'}}>Series/Show: </span>The entire collection of podcast episodes produced under the same title. For example, "Serial" is a popular podcast series with multiple episodes.</li>
            <br />
            <li className='red' style={{fontSize: '14px'}}><span className='glossary-word' style={{color: 'black'}}>Host: </span>The person or people who present and narrate the podcast. They often guide the conversation, interview guests, and provide commentary.</li>
            <br />
            <li className='aqua' style={{fontSize: '14px'}}><span className='glossary-word' style={{color: 'black'}}>Subscribe: </span>The action of following a podcast series within a podcast app to receive new episodes automatically as they are released.</li>
            <br />
            <li className='purple' style={{fontSize: '14px'}}><span className='glossary-word' style={{color: 'black'}}>RSS Feed: </span>A web feed that allows users to access updates to online content in a standardized, computer-readable format. For podcasts, it delivers new episodes to subscribers.</li>
            <br />
            <li className='orange' style={{fontSize: '14px'}}><span className='glossary-word' style={{color: 'black'}}>Download: </span>The process of saving a podcast episode to your device for offline listening. This allows you to listen without needing an internet connection.</li>
            <br />
            <li className='pink' style={{fontSize: '14px'}}><span className='glossary-word' style={{color: 'black'}}>Streaming: </span>Listening to a podcast episode in real-time over the internet without downloading it. Requires an active internet connection.</li>
            <br />
            <li className='green' style={{fontSize: '14px'}}><span className='glossary-word' style={{color: 'black'}}>Show Notes: </span>A summary or detailed notes provided by the podcast creator, usually accompanying each episode. Show notes may include key points, links, and other resources mentioned in the episode.</li>
            <br />
            <li className='blue' style={{fontSize: '14px'}}><span className='glossary-word' style={{color: 'black'}}>Intro/Outro: </span>The introductory and concluding segments of a podcast episode. The intro often includes the podcast’s theme music and introduction, while the outro wraps up the episode and may include calls to action, such as subscribing or following on social media.</li>
            <br />
            <li className='red' style={{fontSize: '14px'}}><span className='glossary-word' style={{color: 'black'}}>Sponsor/Ad Read: </span>A segment within an episode where the host promotes a product, service, or sponsor. These are typically brief advertisements that help fund the podcast.

</li>
            <br /><br />
          {/* Add additional glossary items here */}
          
        </ul>

       
      </div>

      </div>
    </div>
    
    <ScrollToTopButton />
    
    <Footer />
    </Container>
    </div>

  );
};

export default Glossary;
