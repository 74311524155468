import React, { useState, useEffect } from 'react';

import './Timeline.css';
import jsonData from '../data.json';
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import Pill from '../components/Pill';
import Container from '../components/Container';
import Footer from '../components/Footer';
import ScrollToTopButton from '../components/ScrollToTopButton';


const Newsletter = () => {


  const [selectedTag, setSelectedTag] = useState("All");
  
  const handleTagChange = (tag) => {
    // Function to update the selected tag
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    setSelectedTag(tag);

    
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount or update
  }, []);



  return (
    <div
    style={{
      minHeight: '100vh', // Ensures full viewport height
      backgroundColor: '#f7f1e3', // Specific color (in this case, a shade of blue)
      paddingBottom: '0px', // Padding to ensure footer doesn't overlap content
      
    }}
  > <Navbar onTagChange={handleTagChange} />
  
  <Container
      sx={{position: 'relative', bottom: '.0em', top: '0em', marginBottom: '40em'}}>
  
  
    
    
    



    <div className="dark-text-timeline">
    
    <Pill />
    <h1 id="single-guide-header6" style={{color: '#333', marginTop: '1em', fontWeight: 'bold'}}>Newsletter Coming Soon!</h1>
    
    <h3 style={{textAlign: 'center', fontWeight: '500'}}>...and it's going to be awesome! 🤗</h3>

      <ul style={{textAlign: 'left', display: 'grid', fontSize: '14px', lineHeight: 2, justifyContent: 'center', backgroundColor: '#e7e1d1', padding: '40px', borderRadius: '20px', maxWidth: '50em', margin: '0 auto'}}>
          <li >Up-to-date news on the most popular podcasts</li>
          <li>Spotlight on interesting new podcasts</li>
          <li>Tips for starting your own podcast</li>
          <li>Tech talk about gear, software, etc.</li>
          <li>Listener recommendations</li>
          <li>Industry news</li>
          <li>Upcoming events</li>
          <li>How to guides and more.</li>

     
          <p style={{textAlign: 'center', marginTop: '2em'}}>Stay Tuned!</p>

      </ul>
      
     
     
    </div>




    </Container>
    <ScrollToTopButton />
   
    </div>
  );
};

export default Newsletter;