import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Container from '../components/Container';
import Pill from '../components/Pill';
import Footer from '../components/Footer';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add your email sending logic here
    console.log(formData);
  };


  const [selectedTag, setSelectedTag] = useState("All");
  
  const handleTagChange = (tag) => {
    // Function to update the selected tag
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    setSelectedTag(tag);

    
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#f7f1e2',
        paddingBottom: '0px',
        fontFamily: 'Bricolage Grotesque',
      }}
    >
      <Navbar onTagChange={handleTagChange}  />
      <Container >
      <div className="glossary-container">
        <Pill />
        <div className="glossary-header" style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '400px', padding: '20px', boxShadow: '0px 2px 8px #666', border: '0px solid #fcaa97', borderRadius: '12px', marginTop: '2em', marginBottom: '-8em', backgroundColor: '#e7e1d1' }}>
            <h2 style={{ color: '#333', textAlign: 'center' }}>Contact Us</h2>
            <p style={{ color: '#333', textAlign: 'center', marginBottom: '2.5em', fontSize: '14px' }}>Reach out to us for inquiries, suggestions or bug reports. We value your input as we strive to enhance your experience. </p>
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: '10px' }}>
                <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} style={{ width: 'calc(100% - 24px)', padding: '8px', borderRadius: '5px', border: '1px solid #ccc', fontFamily: 'Bricolage Grotesque' }} />
              </div>
              <div style={{ marginBottom: '10px' }}>
                <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} style={{ width: 'calc(100% - 24px)', padding: '8px', borderRadius: '5px', border: '1px solid #ccc', fontFamily: 'Bricolage Grotesque' }} />
              </div>
              <div style={{ marginBottom: '10px' }}>
                <textarea name="message" placeholder="Your Message" rows="8" value={formData.message} onChange={handleChange} style={{ width: 'calc(100% - 24px)', padding: '8px', borderRadius: '5px', border: '1px solid #ccc', fontFamily: 'Bricolage Grotesque' }}></textarea>
              </div>
              <div style={{ textAlign: 'center' }}>
                <button type="submit" style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', boxShadow: '0px 1px 5px #666', backgroundColor: '#fcaa97', color: '#333', cursor: 'pointer' }}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
      </Container>
    </div>
  );
};

export default ContactForm;
