import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        
        <footer className="footer" style={{marginTop: '10em'}}>
            <div className="footer-container">
                
                    
                <div className="footer-bottom" style={{fontSize: '14px'}}>
                    <a href="#">Terms</a>
                    <a href="#">Privacy Policy</a>
                    <a href="#">Cookie Policy</a>
                </div>
            </div>
        </footer>
       
    );
};

export default Footer;
