import React, { useState, useEffect } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import './Navbar.css'; // Import your CSS file for additional styles
import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Slide from '@mui/material/Slide'; // Custom transition
import { Grid } from '@mui/material';
import data from '../data.json';
import SearchBar from './SearchBar';



const Navbar = ( { onTagChange, onLogoClick, onLinkClick, onClearTag, toolData, onCategorySelect, categories, selectedCategory, onToolFilter }) => {
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [menuType, setMenuType] = React.useState(null);
  const navigate = useNavigate(); // For navigation within the router
  const [searchResults, setSearchResults] = useState(data.tools); // Initialize with all tools

  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1024px)');
  const isDesktop = useMediaQuery('(min-width: 1025px)');
  const [tools, setTools] = useState([]);


  const handleMenuClick = (event, type) => {
    setMenuAnchor(event.currentTarget);
    setMenuType(type);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setMenuType(null);
  };

  const handleMenuOpen = (event, type) => {
    setMenuAnchor(event.currentTarget); // Set the anchor for the menu
    setMenuType(type); // Set the menu type (like 'categories')
  };

  const handleLogoClick = () => {

    navigate("/"); // Navigate back to the homepage
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
  };

  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleTagSelection = (tag) => {
    // Update the selected tag
    onTagChange(tag);
  
    // Close the menu
    handleMenuClose();
  
    // Scroll to the top
    window.scrollTo({ top: 0, behavior: 'smooth' });
  
    
  };
  
  const handleTopSelection = (isTopTen) => {
    // Update the selected tag
    onTagChange(isTopTen);
    
  
    // Close the menu
    handleMenuClose();
  
    // Scroll to the top
    window.scrollTo({ top: 0, behavior: 'smooth' });
  
    
  };
  

  useEffect(() => {
    fetch('/path/to/data.json') // Adjust the path to your data.json file
      .then(response => response.json())
      .then(data => {
        const toolsArray = Object.keys(data).map(key => ({
          ...data[key],
          id: key,
        }));
        setTools(toolsArray);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

 

  

  return (
    <AppBar position="sticky" className="navbar" style={{ boxShadow: 'none'}}>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          color: '#D3D3D3',
          backgroundColor: '#f7f1e3',
          fontFamily: 'gabarito',
        }}
      >
        <Container
          maxWidth={false}
          style={{
            maxWidth: '1290px',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Link to="/" style={{textDecoration: 'none'}}>
          <a href="#" onClick={handleLogoClick} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', border: 'none' }}>
          
          <Avatar id="the-logo"
            src="/poddE.png"
            style={{ width: 38, height: 38, marginRight: '5px' }}
          />
          
          <Typography
            variant="h6"
            style={{ color: '#2C3E50', flexShrink: 0, fontWeight: 'bold', fontSize: '20px', textDecoration: 'none' }}
          >
           <span className="title-color">Pod</span>Crate

            
          </Typography></a></Link>




          {(isMobile || isTablet) ? (
            <>
              <IconButton
                color="inherit"
                onClick={(event) => handleMenuOpen(event, 'main')}
                aria-label="menu"
                style={{ position: 'relative', alignItems: 'right', justifyContent: 'space-between', display: 'flex', marginLeft: 'auto', color: '#333'}}
              >
                <MenuIcon />
              </IconButton>

              {/* Mobile Menu */}
              <Menu
                anchorEl={menuAnchor}
                open={menuType === 'main'}
                onClose={handleMenuClose}
                
                style={{ position: 'fixed', left: '1em', top: 0, margin: 0, padding: 0, color: '#333', fontFamily: 'gabarito', }}
                TransitionComponent={Slide}
  TransitionProps={{
    direction: 'left', // Slide left
    timeout: { enter: 300, exit: 300 }, // Define animation duration
  }}
              >
                {/* Open the corresponding submenu */}
                <MenuItem  onClick={(event) => handleMenuOpen(event, 'top charts')} >
                  Top Charts
                </MenuItem>
                <MenuItem onClick={(event) => handleMenuOpen(event, 'society and culture')}>
                  Society & Culture
                </MenuItem>
                <MenuItem onClick={(event) => handleMenuOpen(event, 'education and learning')}>
                  Education & Learning
                </MenuItem>
                <MenuItem onClick={(event) => handleMenuOpen(event, 'entertainment and leisure')}>
                  Entertainment & Leisure
                </MenuItem>
                <MenuItem onClick={(event) => handleMenuOpen(event, 'other-stuff')}>
                  Other Stuff
                </MenuItem>
                <MenuItem>
  <a href="https://podcrate.beehiiv.com/subscribe" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
   Newsletter
  </a>
</MenuItem>



               {/*
                <MenuItem onClick={(event) => handleMenuOpen(event, 'other-stuff')}>
                  Other Stuff
</MenuItem>*/}
              </Menu>

              {/* Submenu for categories */}
              <Menu
  anchorEl={menuAnchor}
  open={menuType === 'society and culture'}
  onClose={handleMenuClose} // Close the menu if it loses focus
  
  style={{ position: 'fixed', left: '1em', margin: 0, padding: 0, color: '#333'  }}
  TransitionComponent={Slide}
TransitionProps={{
direction: 'left', // Slide left
timeout: { enter: 300, exit: 300 }, // Define animation duration
}}

>
  {/* Ensure the menu closes after a selection */}

  


  <Link to="/Society"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Society and Culture");
      handleMenuClose(); // Close the menu
    }}
  >
    Society and Culture
  </MenuItem></Link>
  
  <Link to="/TrueCrime"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("True Crime");
      handleMenuClose(); // Close the menu
    }}
  >
    True Crime
  </MenuItem></Link>

 
  
  <Link to="/News"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("News and Current Events");
      handleMenuClose(); // Close the menu
    }}
  >
    News and Current Events
  </MenuItem></Link>
  
  

  <Link to="/Relationships"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Relationships");
      handleMenuClose(); // Close the menu
    }}
  >
    Relationships
  </MenuItem></Link>

  
  <Link to="/Disaster"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Disaster Events");
      handleMenuClose(); // Close the menu
    }}
  >
    Disaster Events
  </MenuItem></Link>



  <Link to="/Survival"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Survival Stories");
      handleMenuClose(); // Close the menu
    }}
  >
    Survival Stories
  </MenuItem></Link>


  <Link to="/Paranormal"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Paranormal");
      handleMenuClose(); // Close the menu
    }}
  >
    Paranormal
  </MenuItem></Link>


  <Link to="/Criminal"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Criminal Lifestyle");
      handleMenuClose(); // Close the menu
    }}
  >
    Criminal Lifestyle
  </MenuItem></Link>
  
  <Link to="/Scams"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Scams");
      handleMenuClose(); // Close the menu
    }}
  >
    Exposing Scams
  </MenuItem></Link>


  <Link to="/Prison"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Prison Life");
      handleMenuClose(); // Close the menu
    }}
  >
    Prison Life
  </MenuItem></Link>

  <Link to="/Police"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Police Stories");
      handleMenuClose(); // Close the menu
    }}
  >
    Police Stories
  </MenuItem></Link>

  <Link to="/Spy"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Spy");
      handleMenuClose(); // Close the menu
    }}
  >
    Espionage and Spy Stories
  </MenuItem></Link>


  
 

</Menu>








              {/* Submenu for industries */}
              <Menu
  anchorEl={menuAnchor}
  open={menuType === 'education and learning'}
  onClose={handleMenuClose} // Ensure the menu closes if it loses focus
  style={{ position: 'fixed', left: '2em', margin: 0, padding: 0, color: '#333'  }}
  TransitionComponent={Slide}
TransitionProps={{
direction: 'left', // Slide left
timeout: { enter: 300, exit: 300 }, // Define animation duration
}}
>
  {/* Ensure each menu item closes the menu after being clicked */}
  <Link to="/Business" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Business");
      handleMenuClose(); // Close the menu
    }}
  >
    Business
  </MenuItem></Link>
  
  <Link to="/Health" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Health and Fitness");
      handleMenuClose(); // Close the menu
    }}
  >
    Health and Fitness
  </MenuItem></Link>
  
  <Link to="/History" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("History");
      handleMenuClose(); // Close the menu
    }}
  >
    History
  </MenuItem></Link>

  <Link to="/Politics"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Politics");
      handleMenuClose(); // Close the menu
    }}
  >
    Politics
  </MenuItem></Link>

  <Link to="/War"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("War Stories");
      handleMenuClose(); // Close the menu
    }}
  >
    War Stories
  </MenuItem></Link>
  
  <Link to="/Science" style={{textDecoration:'none'}}> <MenuItem
    onClick={() => {
      onTagChange("Science");
      handleMenuClose(); // Close the menu
    }}
  >
    Science
  </MenuItem></Link>
  
  <Link to="/Technology" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Technology");
      handleMenuClose(); // Close the menu
    }}
  >
    Technology
  </MenuItem></Link>

  <Link to="/Ai" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Ai");
      handleMenuClose(); // Close the menu
    }}
  >
    Artificial Intelligence
  </MenuItem></Link>
  
  <Link to="/Finance" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Finance");
      handleMenuClose(); // Close the menu
    }}
  >
    Finance
  </MenuItem></Link>
  
  {/*
  <Link to="/Entertainment" style={{textDecoration:'none'}}> <MenuItem
    onClick={() => {
      onTagChange("Entertainment");
      handleMenuClose(); // Close the menu
    }}
  >
    Entertainment
  </MenuItem></Link>
  */}
    <Link to="/Mental"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Mental Health");
      handleMenuClose(); // Close the menu
    }}
  >
    Mental Health
  </MenuItem></Link>


  <Link to="/SelfImprovement" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Self Improvement");
      handleMenuClose(); // Close the menu
    }}
  >
    Self Improvement
  </MenuItem></Link>

  <Link to="/Entr" style={{textDecoration:'none'}}> <MenuItem
    onClick={() => {
      onTagChange("Entrepreneurship");
      handleMenuClose(); // Close the menu
    }}
  >
    Entrepreneurship
  </MenuItem></Link>
  



</Menu>
















<Menu
  anchorEl={menuAnchor}
  open={menuType === 'entertainment and leisure'}
  onClose={handleMenuClose} // Ensure the menu closes if it loses focus
  style={{ position: 'fixed', left: '2.9em', margin: 0, padding: 0, color: '#333'  }}
  TransitionComponent={Slide}
TransitionProps={{
direction: 'left', // Slide left
timeout: { enter: 300, exit: 300 }, // Define animation duration
}}
>
  {/* Ensure each menu item closes the menu after being clicked */}
  <Link to="/Comedy" style={{textDecoration:'none'}}> <MenuItem
    onClick={() => {
      onTagChange("Comedy");
      handleMenuClose(); // Close the menu
    }}
  >
    Comedy
  </MenuItem></Link>

  <Link to="/Celebrities"  style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Celebrity");
      handleMenuClose(); // Close the menu
    }}
  >
    Celebrities
  </MenuItem></Link>
  
  <Link to="/Music" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Music");
      handleMenuClose(); // Close the menu
    }}
  >
    Music
  </MenuItem></Link>
  
  <Link to="/TV" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("TV and Film");
      handleMenuClose(); // Close the menu
    }}
  >
    TV and Film
  </MenuItem></Link>

  <Link to="/Outdoors" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("The Outdoors");
      handleMenuClose(); // Close the menu
    }}
  >
    The Outdoors
  </MenuItem></Link>
  
  <Link to="/Sports" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Sports");
      handleMenuClose(); // Close the menu
    }}
  >
    Sports
  </MenuItem></Link>

  <Link to="/F1" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("F1");
      handleMenuClose(); // Close the menu
    }}
  >
    Formula 1 Racing
  </MenuItem></Link>
  
  <Link to="/Arts" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Arts");
      handleMenuClose(); // Close the menu
    }}
  >
    Arts
  </MenuItem></Link>

  <Link to="/Horror" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Horror");
      handleMenuClose(); // Close the menu
    }}
  >
    Horror Fiction
  </MenuItem></Link>
  
  <Link to="/Food" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Food and Cooking");
      handleMenuClose(); // Close the menu
    }}
  >
    Food and Cooking
  </MenuItem></Link>


  
  <Link to="/Games" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Games");
      handleMenuClose(); // Close the menu
    }}
  >
    Games
  </MenuItem></Link>

  <Link to="/Cars" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Cars");
      handleMenuClose(); // Close the menu
    }}
  >
    Cars and Driving
  </MenuItem></Link>
  {/*
  <Link to="/Lifestyle" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Lifestyle");
      handleMenuClose(); // Close the menu
    }}
  >
    Lifestyle
  </MenuItem></Link>
  */}
  
  
  
</Menu>






<Menu
  anchorEl={menuAnchor}
  open={menuType === 'other-stuff'}
  onClose={handleMenuClose} // Ensure the menu closes if it loses focus
  style={{left: '3.2em'}}
  TransitionComponent={Slide}
  TransitionProps={{
  direction: 'left', // Slide left
  timeout: { enter: 300, exit: 300 }, // Define animation duration
  }}
>




<Link to="/Rogan" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Rogan");
      handleMenuClose(); // Close the menu
    }}
  >
    The Roganverse
  </MenuItem></Link>

<Link to="/Wondery" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Wondery");
      handleMenuClose(); // Close the menu
    }}
  >
    Wondery Series
  </MenuItem></Link>

  <Link to="/Parcast" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Parcast");
      handleMenuClose(); // Close the menu
    }}
  >
    Parcast Series
  </MenuItem></Link>



<Link to="/Countdown" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Countdown");
      handleMenuClose(); // Close the menu
    }}
  >
    Podcast FAQ
  </MenuItem></Link>
  
  <Link to="/Timeline" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Timeline");
      handleMenuClose(); // Close the menu
    }}
  >
    Podcast Timeline
  </MenuItem></Link>

{/*
  <Link to="/Aiforpodcasts" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("AI Podcast Tools");
      handleMenuClose(); // Close the menu
    }}
  >
    AI Podcast Tools
  </MenuItem></Link>



  <Link to="/Glossary" style={{textDecoration:'none'}}> <MenuItem
    onClick={() => {
      onTagChange("Glossary");
      handleMenuClose(); // Close the menu
    }}
  >
    10 Terms to Know
  </MenuItem></Link>

  <Link to="/Contact" style={{textDecoration:'none'}}> <MenuItem
    onClick={() => {
      onTagChange("Contact");
      handleMenuClose(); // Close the menu
    }}
  >
    Contact Us
  </MenuItem></Link>  */}

  <Link to="/" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      handleLogoClick("HomePage");
      handleMenuClose(); // Close the menu
    }}
  >
    Home Page
  </MenuItem></Link>

  

  <MenuItem > <a href="https://www.facebook.com/profile.php?id=61560894862449" target="_blank" rel="noopener noreferrer">
                <img src="/facebookgrey.png" alt="Facebook" style={{ width: '25px', marginRight: '5px' }} />
              </a>
              <a href="https://x.com/podcrate" target="_blank" rel="noopener noreferrer">
                <img src="/twittergrey.png" alt="Twitter" style={{ width: '25px' }} />
              </a></MenuItem>
  
  
</Menu>
  




<Menu
  anchorEl={menuAnchor}
  open={menuType === 'top charts'}
  onClose={handleMenuClose} // Ensure the menu closes if it loses focus
  style={{ position: 'fixed', left: '5.1em', margin: 0, padding: 0, color: '#333'  }}
  TransitionComponent={Slide}
TransitionProps={{
direction: 'left', // Slide left
timeout: { enter: 300, exit: 300 }, // Define animation duration
}}
>
  {/* Ensure each menu item closes the menu after being clicked */}
  <Link to="/TopTen" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("TopTen");
      handleMenuClose(); // Close the menu
    }}
  >
    Our Top 10
  </MenuItem></Link>
  
  <Link to="/Featured" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Featured");
      handleMenuClose(); // Close the menu
    }}
  >
    Featured
  </MenuItem></Link>

  <Link to="/Popular" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Most Popular");
      handleMenuClose(); // Close the menu
    }}
  >
    Most Popular
  </MenuItem></Link>
  
  {/*
  <Link to="/Stars" style={{textDecoration:'none'}}><MenuItem
    onClick={() => {
      onTagChange("Rising Stars");
      handleMenuClose(); // Close the menu
    }}
  >
    Rising Stars
  </MenuItem></Link> */}


  
  
</Menu>











              
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '4.5em', gap: '10px', fontFamily: 'gabarito',
             }}>

              <Button
                color="inherit"
                endIcon={<ArrowDropDownIcon style={{color: '#333'}}/>}
                
                style={{ textTransform: 'none',  color: '#333'}}
                onClick={(event) => handleMenuOpen(event, 'top charts')}

              >
                Top Charts
              </Button>
              <Menu
                anchorEl={menuAnchor}
                open={menuType === 'top charts'}
                onClose={handleMenuClose}
             
              >
                <Link to={{ pathname: '/topten' }}  style={{ textDecoration: 'none' }}><MenuItem onClick={() => handleTagSelection('Our Top 10')} style={{fontSize: '14px'}}>Our Top 10</MenuItem></Link>
                <Link to={{ pathname: '/featured' }}  style={{ textDecoration: 'none'}}><MenuItem onClick={() => handleTagSelection('Featured')} style={{fontSize: '14px'}}>Featured</MenuItem></Link>
                <Link to={{ pathname: '/popular' }}  style={{ textDecoration: 'none' }}><MenuItem onClick={() => handleTagSelection('Most Popular')} style={{fontSize: '14px'}}>Most Popular</MenuItem></Link>
                {/*<Link to={{ pathname: '/stars' }}  style={{ textDecoration: 'none', color: 'black' }}><MenuItem onClick={() => handleTagSelection('Rising Stars')}>Rising Stars</MenuItem></Link>*/}
               
              </Menu>



              <Button
                color="inherit"
                endIcon={<ArrowDropDownIcon style={{color: '#333'}}/>}
                
                style={{ textTransform: 'none',  color: '#333'}}
                onClick={(event) => handleMenuClick(event, 'keywords')}

              >
                Society & Culture
              </Button>
              <Menu
                id="menu"
                anchorEl={menuAnchor}
                open={menuType === 'keywords'}
                onClose={handleMenuClose}
                MenuListProps={{
                  sx: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
                  },
                }}
              > 
              
              <Grid container>
              <Grid item xs={6}>
              
                              <Link to="/society" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Society")} style={{fontSize: '14px'}}>Society and Culture</MenuItem></Link>
                <Link to="/truecrime" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("TrueCrime")} style={{fontSize: '14px'}}>True Crime</MenuItem></Link>

                

                <Link to="/news" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("News")} style={{fontSize: '14px'}}>News and Current Events</MenuItem></Link>

                

                <Link to="/relationships" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Relationships")} style={{fontSize: '14px'}}>Relationships</MenuItem></Link>

                

                <Link to="/disaster" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("Disaster Events")} style={{fontSize: '14px'}}>Disaster Events</MenuItem></Link>
                
                <Link to="/survival" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("Survival Stories")} style={{fontSize: '14px'}}>Survival Stories</MenuItem></Link>
                </Grid>
                <Grid item xs={6}>
                <Link to="/paranormal" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("Paranormal")} style={{fontSize: '14px'}}>Paranormal</MenuItem></Link>

                <Link to="/criminal" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("Criminal Lifestyle")} style={{fontSize: '14px'}}>Criminal Lifestyle</MenuItem></Link>

                <Link to="/scams" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("Scams")} style={{fontSize: '14px'}}>Exposing Scams</MenuItem></Link>
                <Link to="/prison" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("Prison Life")} style={{fontSize: '14px'}}>Prison Life</MenuItem></Link>
                <Link to="/police" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("Police Stories")} style={{fontSize: '14px'}}>Police Stories</MenuItem></Link>
                <Link to="/spy" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("Spy")} style={{fontSize: '14px'}}>Espionage and Spy Stories</MenuItem></Link>
                </Grid>
                </Grid>
              </Menu>







              <Button
                color="inherit"
                endIcon={<ArrowDropDownIcon style={{color: '#333'}}/>}
                
                style={{ textTransform: 'none',  color: '#333'}}
                onClick={(event) => handleMenuClick(event, 'industries')}

              >
                Education & Learning
              </Button>
              <Menu
                anchorEl={menuAnchor}
                open={menuType === 'industries'}
                onClose={handleMenuClose}
              
              >

                   <Grid container>
              <Grid item xs={6}>
                <Link to="/business" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Business")} style={{fontSize: '14px'}}>Business</MenuItem></Link>
                <Link to="/health" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Health")} style={{fontSize: '14px'}}>Health and Fitness</MenuItem></Link>
                {/*<Link to="/education" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Education")}>Education</MenuItem></Link>*/}
                <Link to="/history" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("History")} style={{fontSize: '14px'}}>History</MenuItem></Link>
                <Link to="/politics" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Politics")} style={{fontSize: '14px'}}>Politics</MenuItem></Link>
                <Link to="/war" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("War Stories")} style={{fontSize: '14px'}}>War Stories</MenuItem></Link>
                <Link to="/science" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Science")} style={{fontSize: '14px'}}>Science</MenuItem></Link>
                </Grid>
                <Grid item xs={6}>
                
                <Link to="/technology" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Technology")} style={{fontSize: '14px'}}>Technology</MenuItem></Link>
                <Link to="/ai" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("ai")} style={{fontSize: '14px'}}>Artificial Intelligence</MenuItem></Link>
             
                <Link to="/finance" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Finance")} style={{fontSize: '14px'}}>Finance</MenuItem></Link>
                <Link to="/mental" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("Mental")} style={{fontSize: '14px'}}>Mental Health</MenuItem></Link>
                <Link to="/selfimprovement" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Self Improvement")} style={{fontSize: '14px'}}>Self Improvement</MenuItem></Link>
                <Link to="/entr" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("entr")} style={{fontSize: '14px'}}>Entrepreneurship</MenuItem></Link>

                </Grid>
                </Grid>


              </Menu>

              


              
              <Button
                color="inherit"
                endIcon={<ArrowDropDownIcon style={{color: '#333'}}/>}
                
                style={{ textTransform: 'none',  color: '#333' }}
                onClick={(event) => handleMenuOpen(event, 'categories')}

              >
                Entertainment & Leisure
              </Button>
              <Menu
                anchorEl={menuAnchor}
                open={menuType === 'categories'}
                onClose={handleMenuClose}
               
              >
              <Grid container>
              <Grid item xs={5.8}>
                <Link to="/comedy"  style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Comedy")} style={{fontSize: '14px'}}>Comedy</MenuItem></Link>
                <Link to="/celebrities" style={{textDecoration:'none'}}> <MenuItem onClick={() => handleTagSelection("Celebrities")} style={{fontSize: '14px'}}>Celebrities</MenuItem></Link>
                <Link to="/music"  style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Music")} style={{fontSize: '14px'}}>Music             </MenuItem></Link>
                <Link to="/TV"  style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("TV")} style={{fontSize: '14px'}}>TV and Film</MenuItem></Link>
                <Link to="/outdoors"  style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("The Outdoors")} style={{fontSize: '14px'}}>The Outdoors</MenuItem></Link>
                <Link to="/food"  style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Food")} style={{fontSize: '14px'}}>Food and Cooking</MenuItem></Link>

                </Grid>
                <Grid item xs={6}>
               
                <Link to="/sports" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Sports")} style={{fontSize: '14px'}}>Sports</MenuItem></Link>

                <Link to="/arts" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Arts")} style={{fontSize: '14px'}}>Arts</MenuItem></Link>
                <Link to="/horror" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Horror")} style={{fontSize: '14px'}}>Horror Fiction</MenuItem></Link>
                <Link to="/games"  style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Games")} style={{fontSize: '14px'}}>Games</MenuItem></Link>
                <Link to="/cars"  style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("Cars")} style={{fontSize: '14px'}}>Cars and Driving</MenuItem></Link>
                <Link to="/f1" style={{textDecoration:'none'}}><MenuItem onClick={() => handleTagSelection("F1")} style={{fontSize: '14px'}}>Formula 1 Racing</MenuItem></Link>

         

               </Grid>
                </Grid>
              </Menu>




             

              <Button
                color="inherit"
                endIcon={<ArrowDropDownIcon  style={{color: '#333'}}/>}
                
                style={{ textTransform: 'none', marginLeft: '0em' , color: '#333' }}
                onClick={(event) => handleMenuClick(event, 'other-stuff')}

              >
                Other Stuff
              </Button>
              <Menu
                anchorEl={menuAnchor}
                open={menuType === 'other-stuff'}
                onClose={handleMenuClose}
                style={{ width: '30em'}}

              >
                  <Link to="https://podcrate.beehiiv.com/" style={{textDecoration: 'none'}}><MenuItem style={{fontSize: '14px', color: '#E74C3C'}} >Free Newsletter</MenuItem></Link>
                <Link to={{ pathname: '/rogan' }}  style={{ textDecoration: 'none'}}><MenuItem onClick={() => handleTagSelection('Rogan')} style={{fontSize: '14px'}}>The Roganverse</MenuItem></Link>
                 <Link to={{ pathname: '/wondery' }}  style={{ textDecoration: 'none'}}><MenuItem onClick={() => handleTagSelection('Wondery')} style={{fontSize: '14px'}}>Wondery Series</MenuItem></Link>
                 <Link to={{ pathname: '/parcast' }}  style={{ textDecoration: 'none' }}><MenuItem onClick={() => handleTagSelection('Parcast')} style={{fontSize: '14px'}}>Parcast Series</MenuItem></Link>
                {/*<Link to="https://podcrate.beehiiv.com/" style={{textDecoration: 'none', color: 'black'}}><MenuItem   style={{fontSize: '14px', color: '#e8826d'}}>Our Newsletter</MenuItem></Link>*/}
                <Link to="/countdown" style={{textDecoration: 'none'}}><MenuItem   style={{fontSize: '14px'}}>Podcast FAQ</MenuItem></Link>


                <Link to="/timeline" style={{textDecoration: 'none'}}><MenuItem   style={{fontSize: '14px'}}>Podcast Timeline</MenuItem></Link>
               {/* <Link to="/courses" style={{textDecoration: 'none', color: 'black'}}><MenuItem  >Start a Podcast</MenuItem></Link>*/}
              {/* <Link to="/aiforpodcasts" style={{textDecoration: 'none'}}><MenuItem   style={{fontSize: '14px'}}>AI Podcast Tools</MenuItem></Link>
                <Link to="/glossary" style={{textDecoration: 'none'}}><MenuItem style={{fontSize: '14px'}}  >10 Terms to Know</MenuItem></Link>
                {/*<Link to="/contact" style={{textDecoration: 'none', color: 'black'}}><MenuItem style={{fontSize: '14px'}}  >Contact Us</MenuItem></Link>*/}
                                

                <Link to="/" style={{textDecoration: 'none'}}><MenuItem style={{fontSize: '14px'}} >Home</MenuItem></Link>
                <MenuItem > <a href="https://www.facebook.com/profile.php?id=61560894862449" target="_blank" rel="noopener noreferrer">
                <img src="/facebookgrey.png" alt="Facebook" style={{ width: '25px', marginRight: '5px' }} />
              </a>
              <a href="https://x.com/podcrate" target="_blank" rel="noopener noreferrer">
                <img src="/twittergrey.png" alt="Twitter" style={{ width: '25px' }} />
              </a></MenuItem>
              
              </Menu>
          
          
 
              <SearchBar tools={searchResults} />


            </div>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
