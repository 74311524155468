import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import './NewsletterCTA.css'

const NewsletterCTA = ({ tools }) => {
  // Assuming you have a state or method to determine if it's mobile
  const isMobile = useMediaQuery('(max-width: 600px)');
  
  const buttonStyle = {
    height: isMobile ? '40px' : '22px', // Adjusted height for mobile
    marginTop: isMobile ? '-90px' : '3px',
    marginRight: '10px',
    fontSize: isMobile ? '18px' : '12px', // Adjusted font size for mobile
    marginBottom: isMobile ? '2.5em' : '2em',
    backgroundColor: '#f7f1e3'
  };

  return (
    <Link to="https://podcrate.beehiiv.com/" style={{textDecoration: 'none'}}>{/*<div style={{ display: 'flex', justifyContent: 'center', top: '2em', position: 'relative' }}>
      
      
      <div className="newsletter-button" style={buttonStyle}>
        <span className="icon"><img src="letter.png" alt="Mail Icon" style={{ width: '17px', marginTop: '2px' }} /></span>
        <span className="text" style={{color: '#E74C3C'}}>Get Our Free Newsletter!</span>
      </div>
    </div>*/}

<button className="newsletter-button" style={{ fontFamily: "Bricolage Grotesque", marginBottom: '7em'}}>Get Newsletter!</button>


    </Link>
  );
};

export default NewsletterCTA;
