import React, { useState } from 'react';
import { List, ListItem, ListItemText, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import './SearchBar.css';

const SearchBar = ({ tools }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.length > 1 && tools) {
      const filteredResults = tools.filter(tool =>
        tool.name.toLowerCase().includes(term.toLowerCase()) ||
        tool.description.toLowerCase().includes(term.toLowerCase())
      );
      setResults(filteredResults);
    } else {
      setResults([]);
    }
  };

  const handleSelect = (toolId) => {
    setSearchTerm('');
    setResults([]);
    // Extract the numerical part of the toolId
    const numericId = toolId.replace('tool', '');
    navigate(`/podcast/${numericId}`, { state: { searchResults: results } });
  };

  const getSnippet = (description, term) => {
    const lowerDescription = description.toLowerCase();
    const index = lowerDescription.indexOf(term);

    if (index !== -1) {
      const start = index;
      const end = index + term.length;
      const snippet = description.slice(start, end + description.slice(end).search(/\s/));

      return snippet.length > 0 ? `...${snippet.trim()}...` : '';
    }
    return '';
  };

  return (
    <div className="custom-search-container" style={{postion: 'relative', top: '.2em', left: '1em'}}>
      <div className="search-bar-wrapper">
        <SearchIcon className="search-icon" />
        <input
          type="text"
          placeholder="Search Podcasts..."
          value={searchTerm}
          onChange={handleSearch}
          className="custom-search-input"
        />
      </div>
      {results.length > 0 && (
        <Paper className="search-results-dropdown">
          <List className="results-list">
            {results.map((tool) => (
              <ListItem button key={tool.id} onClick={() => handleSelect(tool.id)}>
                <ListItemText
                  primary={tool.name}
                  secondary={tool.description.toLowerCase().includes(searchTerm) ? getSnippet(tool.description, searchTerm) : ''}
                  className="result-text"
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
};

export default SearchBar;
