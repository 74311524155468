import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Always scroll to the top with smooth behavior
  }, [location]); // Trigger scroll on every location change

  return null; // This component doesn't render any visual content
};

export default ScrollToTop;
